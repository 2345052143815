import axios from 'axios';
import { redirect } from 'react-router-dom';
import { login } from '../slices/auth';
import { companiesDelFail, companiesDelInit, companiesDelSuccess, companiesFail, companiesInit, companiesSuccess } from '../slices/companies';
import { countriesFail, countriesInit, countriesSuccess } from '../slices/countries';
import {
  towerCompaniesDelFail,
  towerCompaniesDelInit,
  towerCompaniesDelSuccess,
  towerCompaniesFail,
  towerCompaniesInit,
  towerCompaniesSuccess,
} from '../slices/tower-companies';
import {
  usersDelFail,
  usersDelInit,
  usersDelSuccess,
  usersFail,
  usersInit,
  usersSuccess,
  usersUpdateFail,
  usersUpdateInit,
  usersUpdateSuccess,
} from '../slices/users';
import { userTypesFail, userTypesInit, userTypesSuccess } from '../slices/user-types';
import { store } from '../store';
import { towerSitesDelFail, towerSitesDelInit, towerSitesDelSuccess, towerSitesFail, towerSitesInit, towerSitesSuccess } from '../slices/tower-sites';
import { formRest } from '../components/common/forms/validator';
import { getToday, makeObjectAsGroupFromArray, timeConvert } from '../utils/utils';
import { devicesDelFail, devicesDelInit, devicesDelSuccess, devicesFail, devicesInit, devicesSuccess } from '../slices/devices';
import { pgsDelFail, pgsDelInit, pgsDelSuccess, pgsFail, pgsInit, pgsSuccess } from '../slices/pgs';
import { userPgsDelFail, userPgsDelInit, userPgsDelSuccess, userPgsFail, userPgsInit, userPgsSuccess } from '../slices/user-pgs';
import { deviceLogsFail, deviceLogsInit, deviceLogsSuccess } from '../slices/device-logs';
import { reportsFail, reportsInit, reportsSuccess } from '../slices/reports';
import { dashboardFail, dashboardInit, dashboardSuccess } from '../slices/home-dash';
import { activeDevicesFail, activeDevicesInit, activeDevicesSuccess } from '../slices/active-devices';
import { faultTypesFail, faultTypesInit, faultTypesSuccess, faultTypesDelFail, faultTypesDelInit, faultTypesDelSuccess } from '../slices/fault-types';
import { zonesDelFail, zonesDelInit, zonesDelSuccess, zonesFail, zonesInit, zonesSuccess } from '../slices/zones';
import { ticketsFail, ticketsInit, ticketsSuccess } from '../slices/tickets';

export const baseUrl = process.env.REACT_APP_BASE_URL;
export const credentials = {
  getTokens: () => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    return { token, refreshToken };
  },
  setTokens: (option = {}) => {
    for (const key in option) {
      localStorage.setItem(key, option[key]);
    }
  },
  getHeaders: () => {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${credentials.getTokens().token}`,
    };
    return headers;
  },
};

export const SUPER_ADMIN = 1;
export const CUSTOMER_ADMIN = 2;
export const CUSTOMER_USER = 3;
export const refreshTime = 1000 * 10;

export async function userPasswordChange(postData = {}) {
  try {
    const response = await axios.post(`${baseUrl}/user/change-password`, postData, {
      headers: credentials.getHeaders(),
    });
    const { status, token, refreshToken } = response.data;
    if (status) {
    }
    credentials.setTokens({ token, refreshToken });
    return { data: response.data };
  } catch (error) {
    const { data, status, statusText, headers } = error.response;
    return { data: { data, status, statusText, headers } };
  }
}

export async function userPasswordResetGetLink(postData = {}) {
  try {
    const response = await axios.get(`${baseUrl}/auth/reset-password?email=${postData.email}`);
    return { data: response.data };
  } catch (error) {
    const { data, status, statusText, headers } = error.response;
    return { data: { data, status, statusText, headers } };
  }
}

export async function userPasswordReset(postData = {}) {
  try {
    const response = await axios.post(`${baseUrl}/auth/user/reset-password-update`, postData);
    return { data: response.data };
  } catch (error) {
    const { data, status, statusText, headers } = error.response;
    return { data: { data, status, statusText, headers } };
  }
}

export async function newTokenGenerate(rToken) {
  try {
    const response = await axios.post(`${baseUrl}/auth/user/token`, { refreshToken: rToken });
    credentials.setTokens({ token: response.data.token, refreshToken: response.data.refreshToken });
    getUser();
    return true;
  } catch (error) {
    return false;
  }
}

export async function loginByToken(postData, navigate = () => {}) {
  try {
    const response = await axios.post(`${baseUrl}/auth/user/login`, postData);
    const { token, refreshToken } = response.data;
    credentials.setTokens({ token, refreshToken });
    navigate();
  } catch (error) {
    console.log(error);
    alert(error.response.data.error);
  }
}

export async function getUser() {
  const { token, refreshToken } = credentials.getTokens();

  if (token !== null && refreshToken !== null) {
    try {
      const response = await axios.get(`${baseUrl}/user/myinfo`, { headers: credentials.getHeaders() });
      store.dispatch(login({ user: response.data, isLoading: false }));
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.data.error === 'jwt expired') {
          const result = await newTokenGenerate(refreshToken);
          console.log(result);
          if (!result) return redirect('/login');
        } else {
          return redirect('/login');
        }
      }
    }
  } else {
    return redirect('/login');
  }
}

export function deleteUser(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }
  deleteApiCall(
    {
      url: `user/delete/${ids.join(',')}`,
      init: usersDelInit,
      success: usersDelSuccess,
      fail: usersDelFail,
    },
    ids
  );
}

//update User form has slight deferent
export async function userFormUpdate(postData = {}) {
  try {
    const response = await axios.post(`${baseUrl}/user/profile-update`, postData, {
      headers: credentials.getHeaders(),
    });
    store.dispatch(login({ user: response.data, isLoading: false }));
    return { data: response.data };
  } catch (error) {
    const { data, status, statusText, headers } = error.response;
    return { data: { data, status, statusText, headers } };
  }
}

export async function updateUserFromUsers(postData = {}) {
  updateApiCall(
    {
      url: `admin/user/account-active`,
      init: null,
      success: usersUpdateSuccess,
      fail: usersUpdateFail,
    },
    postData
  );
}

//common api methods
export async function getApiCall({ url = '', init = () => {}, success = () => {}, fail = () => {} }, pagination = false) {
  store.dispatch(init());
  try {
    const response = await axios.get(`${baseUrl}/${url}`, { headers: credentials.getHeaders() });
    if (pagination) {
      store.dispatch(success({ data: response.data }));
    } else {
      store.dispatch(success({ data: response.data.data }));
    }
  } catch (error) {
    const { data, status, statusText, headers } = error.response;
    store.dispatch(fail({ data: { data, status, statusText, headers } }));
  }
}

export async function deleteApiCall({ url = '', init = () => {}, success = () => {}, fail = () => {} }, arr = [] || {}) {
  store.dispatch(init());
  try {
    await axios.delete(`${baseUrl}/${url}`, { headers: credentials.getHeaders() });
    store.dispatch(success({ data: arr }));
  } catch (error) {
    console.log('errr==', error);
    const { data, status, statusText, headers } = error.response;
    store.dispatch(fail({ data: { data, status, statusText, headers } }));
  }
}

export async function postFormData(postData = {} || [], url = '', resetter = () => {}) {
  try {
    const response = await axios.post(`${baseUrl}/${url}`, postData, { headers: credentials.getHeaders() });
    alert(`${response.data.message}`);
    resetter();
  } catch (error) {
    alert(`${error.response.data.error}`);
  }
}

//inprogress
export async function updateApiCall({ url = '', init = () => {}, success = () => {}, fail = () => {} }, payload = {}) {
  if (init) {
    store.dispatch(init());
  }
  try {
    await axios.post(`${baseUrl}/${url}`, payload, { headers: credentials.getHeaders() });
    store.dispatch(success({ data: payload || {} }));
  } catch (error) {
    console.log('errr==', error);
    const { data, status, statusText, headers } = error.response;
    store.dispatch(fail({ data: { data, status, statusText, headers } }));
  }
}

//Xlsx File upload
export async function postDataFromFile(postData = [], url = '') {
  let groupSize = 100;
  const objectOfGroup = makeObjectAsGroupFromArray([...postData], groupSize);
  let duplicate = [];
  let err = false;
  let newCreate = [];
  for (const key in objectOfGroup) {
    try {
      const response = await axios.post(`${baseUrl}/${url}`, objectOfGroup[key], { headers: credentials.getHeaders() });
      if (response.data.status === true) {
        if (response.data.duplicateEntries) {
          duplicate.push(response.data.duplicateEntries);
        }
        if (response.data.data.length > 0) {
          newCreate.push(response.data.data);
        }
        formRest();
      }
    } catch (error) {
      err = error.response.data.error;
      alert(err);
      formRest();
    }
  }
  return { duplicate: duplicate.flat(1), newCreate: newCreate.flat(1), err };
}

//for getUser has different response Data for pagination
export async function getUsers(options = {}) {
  let page = options.page || 0;
  let per_page = options.per_page || 10;
  let company_id = options.company_id || '';
  store.dispatch(usersInit());
  try {
    const response = await axios.get(`${baseUrl}/users?company_id=${company_id}&page=${page}&per_page=${per_page}`, {
      headers: credentials.getHeaders(),
    });
    store.dispatch(usersSuccess({ data: response.data }));
  } catch (error) {
    const { data, status, statusText, headers } = error.response;
    store.dispatch(usersFail({ data: { data, status, statusText, headers } }));
  }
}

//for userType
export async function getUserTypes() {
  getApiCall({ url: 'common-api/user-types', init: userTypesInit, success: userTypesSuccess, fail: userTypesFail });
}

// for country
export async function getCountries() {
  await getApiCall({
    url: 'common-api/countries',
    init: countriesInit,
    success: countriesSuccess,
    fail: countriesFail,
  });
}

//for company
export async function getCompanies() {
  getApiCall({ url: 'company/all', init: companiesInit, success: companiesSuccess, fail: companiesFail });
}

export function deleteCompanies(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `company/delete/${ids.join(',')}`,
      init: companiesDelInit,
      success: companiesDelSuccess,
      fail: companiesDelFail,
    },
    ids
  );
}

// for tower company
export async function getTowerCompanies() {
  getApiCall({
    url: 'tower-company/all',
    init: towerCompaniesInit,
    success: towerCompaniesSuccess,
    fail: towerCompaniesFail,
  });
}

export function deleteTowerCompanies(idOrIds = []) {
  let ids = [];
  console.log(typeof idOrIds);
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }
  deleteApiCall(
    {
      url: `tower-company/delete/${ids.join(',')}`,
      init: towerCompaniesDelInit,
      success: towerCompaniesDelSuccess,
      fail: towerCompaniesDelFail,
    },
    ids
  );
}

//for tower sites
export function getTowerSites(options = {}) {
  getApiCall(
    {
      url: `tower-sites/all?${joinQueryParam(options)}`,
      init: towerSitesInit,
      success: towerSitesSuccess,
      fail: towerSitesFail,
    },
    true
  );
}

export function deleteTowerSites(idOrIds = []) {
  let ids = [];
  console.log(typeof idOrIds);
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `tower-sites/delete/${ids.join(',')}`,
      init: towerSitesDelInit,
      success: towerSitesDelSuccess,
      fail: towerSitesDelFail,
    },
    ids
  );
}

//for devices
export async function getDevices(options = {}) {
  getApiCall(
    {
      url: `think-device/all?${joinQueryParam(options)}`,
      init: devicesInit,
      success: devicesSuccess,
      fail: devicesFail,
    },
    true
  );
}

export function deleteDevices(idOrIds = []) {
  let ids = [];
  console.log(idOrIds);
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `think-device/delete/${ids.join(',')}`,
      init: devicesDelInit,
      success: devicesDelSuccess,
      fail: devicesDelFail,
    },
    ids
  );
}

export function joinQueryParam(obj = {}) {
  let stringArray = Object.keys(obj).map((item, key) => `${item}=${obj[item]}`);
  return stringArray.join('&');
}
//for pgs
export async function getPgs(options = {}) {
  getApiCall(
    {
      url: `pg-name/all?${joinQueryParam(options)}`,
      init: pgsInit,
      success: pgsSuccess,
      fail: pgsFail,
    },
    true
  );
}

export function deletePgs(idOrIds = []) {
  let ids = [];
  console.log(typeof idOrIds);
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `pg-name/delete/${ids.join(',')}`,
      init: pgsDelInit,
      success: pgsDelSuccess,
      fail: pgsDelFail,
    },
    ids
  );
}

export async function getUserPgs(options = {}) {
  getApiCall({
    url: `user-pg-name/all?${joinQueryParam(options)}`,
    init: userPgsInit,
    success: userPgsSuccess,
    fail: userPgsFail,
  });
}

export function detachUserPg(options = {}) {
  const user_id = options.user_id || null;
  const pg_number = options.pg_number || null;

  deleteApiCall(
    {
      url: `user-pg-name/delete/${user_id}/${pg_number}`,
      init: userPgsDelInit,
      success: userPgsDelSuccess,
      fail: userPgsDelFail,
    },
    options
  );
}

export async function getActiveDeviceLogs(options = {}) {
  store.dispatch(deviceLogsInit());
  try {
    const response = await axios.get(`${baseUrl}/pg-log-data/all?${joinQueryParam(options)}`, {
      headers: credentials.getHeaders(),
    });
    store.dispatch(deviceLogsSuccess({ data: response.data }));
  } catch (error) {
    const { data, status, statusText, headers } = error.response;
    store.dispatch(deviceLogsFail({ data: { data, status, statusText, headers } }));
  }
}

export async function getReports(options = {}) {
  store.dispatch(reportsInit());
  try {
    const response = await axios.get(`${baseUrl}/pg-stats-data/all?${joinQueryParam(options)}`, { headers: credentials.getHeaders() });
    store.dispatch(reportsSuccess({ data: response.data }));
  } catch (error) {
    const { data, status, statusText, headers } = error.response;
    store.dispatch(reportsFail({ data: { data, status, statusText, headers } }));
  }
}

export async function downloadReport(options = {}) {
  try {
    axios.get(`${baseUrl}/pg-stats-data/all?${joinQueryParam({ ...options, download: 'yes' })}`, {
      headers: credentials.getHeaders(),
    });
    window.open(`${baseUrl}/pg-stats-data/all?${joinQueryParam({ ...options, download: 'yes' })}`, '_blank');
  } catch (error) {
    const { data, status, statusText, headers } = error.response;
  }
}

export async function downloadDeviceLogs(options = {}) {
  try {
    axios.get(`${baseUrl}/pg-log-data/all?${joinQueryParam({ ...options, download: 'yes' })}`, {
      headers: credentials.getHeaders(),
    });
    window.open(`${baseUrl}/pg-log-data/all?${joinQueryParam({ ...options, download: 'yes' })}`, '_blank');
  } catch (error) {
    const { data, status, statusText, headers } = error.response;
  }
}
//for home page

export async function getDashboardData(options = {}) {
  let dataArray = [];
  if (typeof (options.data || {}) === 'object') {
    dataArray.push(options.data);
  } else {
    dataArray = options.data;
  }
  let modifyOptions = {};
  if (options.data) {
    for (let key in options) {
      if (key !== 'data') {
        modifyOptions[key] = options[key];
      }
    }
  } else {
    modifyOptions = options;
  }
  getApiCall({
    url: `pg-stats-data/dashboard?data=${dataArray.join(',')}&${joinQueryParam(modifyOptions)}`,
    init: dashboardInit,
    success: dashboardSuccess,
    fail: dashboardFail,
  });
}

export async function getActiveDevices(options = {}) {
  getApiCall(
    {
      url: `pg-stats-data/active?${joinQueryParam(options)}`,
      init: activeDevicesInit,
      success: activeDevicesSuccess,
      fail: activeDevicesFail,
    },
    true
  );
}

export async function getChatMessages(options = {}) {
  try {
    const response = await axios.get(`${baseUrl}/chat-log/all?${joinQueryParam({ ...options })}`, {
      headers: credentials.getHeaders(),
    });
    return { data: response.data };
  } catch (error) {
    console.log(error);
    // const { data, status, statusText, headers } = error.response;
    return { data: { status: false } };
  }
}

export async function getChatAllUser(options = {}) {
  try {
    const response = await axios.get(`${baseUrl}/chat-user/all?${joinQueryParam({ ...options })}`, {
      headers: credentials.getHeaders(),
    });
    return { data: response.data };
  } catch (error) {
    console.log(error);
    // const { data, status, statusText, headers } = error.response;
    return { data: { status: false } };
  }
}

export async function getHomeUrl() {
  try {
    const response = await axios.get(`${baseUrl}/user/myinfo`, { headers: credentials.getHeaders() });
    console.log(response);
  } catch (error) {
    return;
  }
}

//faulty category

export async function getFaultTypes(options) {
  getApiCall(
    {
      url: `fault-type/all?${joinQueryParam(options)}`,
      init: faultTypesInit,
      success: faultTypesSuccess,
      fail: faultTypesFail,
    },
    true
  );
}

export function deleteFaultTypes(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `fault-type/delete/${ids.join(',')}`,
      init: faultTypesDelInit,
      success: faultTypesDelSuccess,
      fail: faultTypesDelFail,
    },
    ids
  );
}

//zone api

export async function getZones(options) {
  getApiCall({ url: `zone/all?${joinQueryParam(options)}`, init: zonesInit, success: zonesSuccess, fail: zonesFail }, true);
}

export function deleteZones(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `zone/delete/${ids.join(',')}`,
      init: zonesDelInit,
      success: zonesDelSuccess,
      fail: zonesDelFail,
    },
    ids
  );
}

// ticket api

export async function getTickets(options) {
  getApiCall({ url: `support-case/all?${joinQueryParam(options)}`, init: ticketsInit, success: ticketsSuccess, fail: ticketsFail }, true);
}

export function deleteTickets(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `zone/delete/${ids.join(',')}`,
      init: zonesDelInit,
      success: zonesDelSuccess,
      fail: zonesDelFail,
    },
    ids
  );
}
