import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CUSTOMER_ADMIN, detachUserPg, getUserPgs, SUPER_ADMIN } from '../../../apis/apis';
import NoData from '../../../components/common/no-data/NoData';
import Suspender from '../../../components/common/suspender/Suspender';

import './style.css';

export default function UserPGList() {
  const { isLoading, isError, list } = useSelector((state) => state.userPgs);

  const {
    user: {
      data: { id, user_type, company },
    },
  } = useSelector((state) => state.auth) || {};
  const param = useParams();

  const [queryObj, updateQueryObject] = useState({
    page: 0,
    per_page: 10,
    user_id: param.userId,
  });

  useEffect(() => {
    getUserPgs(queryObj);
  }, [queryObj]);

  return (
    <Suspender isLoading={isLoading} isError={isError}>
      <div className='user-pgs p-4'>
        <div className='table-container h-[530px] overflow-y-auto'>
          <table className='w-full text-center text-primary-dark '>
            <thead className='h-12'>
              <tr>
                <th>
                  <pre>PG Number</pre>
                </th>
                <th>
                  <pre>PG Name </pre>
                </th>
                <th>
                  <pre>Address</pre>
                </th>
                <th>
                  <pre>Company Id</pre>
                </th>
                <th>
                  <pre>Device Id</pre>
                </th>
                {[SUPER_ADMIN, CUSTOMER_ADMIN].includes(user_type) && (
                  <th>
                    <pre>Action</pre>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {list?.map((item) => (
                <tr key={item.pg_number}>
                  <td className=''>{item.pg_number || '-'}</td>
                  <td>{item.pg_name || '-'}</td>
                  <td>{item.address + ',' + item.city || '-'}</td>

                  <td>{item.company_id || '-'}</td>
                  <td>{item.device_id || 'Not Assigned'}</td>
                  {[SUPER_ADMIN, CUSTOMER_ADMIN].includes(user_type) && (
                    <td>
                      <h4
                        onClick={() => detachUserPg({ user_id: param.userId, pg_number: item.pg_number })}
                        className='mx-auto h-6 w-12 rounded-md cursor-pointer border border-[#dc3545] flex items-center justify-center'
                      >
                        detach
                      </h4>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {list?.length === 0 && <NoData />}
        </div>

        <div className='my-4'>
          {/* <PaginatedItems
            itemsPerPage={queryObj.per_page}
            onClick={(parm) => updateQueryObject({ ...queryObj, page: parm })}
            totalItems={list?.length}
          /> */}
        </div>
      </div>
    </Suspender>
  );
}
