import React from 'react';
import './FullCircle.css';

export default function FullCircle({ percentage = 100, children }) {
  return (
    <div className='full-circle'>
      <svg width='110' height='110' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='55' strokeWidth={20} cy='55' r='40' stroke='#dbdada' />
        <circle
          cx='55'
          strokeWidth={20}
          strokeDashoffset={360 - (251 / 300) * percentage}
          cy='55'
          r='40'
          strokeDasharray={360}
          stroke='#f7bb17'
          fill='#dbdada'
        />
        <circle cx='55' cy='55' r='38' fill='black' />
        <circle cx='55' cy='55' r='28' fill='#d79f29' />
      </svg>
      {children && children}
    </div>
  );
}
