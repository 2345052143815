import { capitalize } from './makeSubmittableData';

export const validation = {
  name: (name, value) => {
    const capName = capitalize(name).split('_').join(' ');
    if (value === '' || undefined) {
      return `${capName} is required`;
    }
    return false;
  },
  password: (name, value) => {
    const capName = capitalize(name).split('_').join(' ');
    if (value === '' || undefined) {
      return `${capName} is required`;
    } else if (value.length < 4 || value.length > 200) {
      return `${capName} must be between 4 to 20`;
    }
    return false;
  },
  email: (name, value) => {
    const capName = capitalize(name).split('_').join(' ');
    if (value === '' || undefined) {
      return `${capName} is required`;
    } else if (value.length < 4 || value.length > 200) {
      return `${capName} must be between 4 to 20`;
    }
    return false;
  },
  phone: (name, value) => {
    const capName = capitalize(name).split('_').join(' ');
    if (value === '' || undefined) {
      return `${capName} is required`;
    } else if (value.length < 11 || value.length > 13) {
      return `${capName} must be  11 digits`;
    }
    return false;
  },
  search: (name, value) => {
    const capName = capitalize(name).split('_').join(' ');
    if (value === '' || undefined) {
      return `${capName} is required`;
    }
    return false;
  },
  required: (name, value) => {
    const capName = capitalize(name).split('_').join(' ');
    if (value === '' || undefined) {
      return `${capName} is required`;
    }
    return false;
  },
  number: (name, value) => {
    const capName = capitalize(name).split('_').join(' ');
    if (value === '' || undefined) {
      return `${capName} is required`;
    } else if (isNaN(value)) {
      return `${capName} must be a Number`;
    }
    return false;
  },
};

export function checkEmptyObject(object) {
  return !Object.keys(object).length;
}

export function checkEmptyArray(arr) {
  return !arr.length;
}

export function formRest() {
  const inputs = document.getElementsByTagName('input');
  for (let i = 0; i < inputs.length; i++) {
    inputs[i]['value'] = '';
  }
}
