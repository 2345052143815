import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

export default function ErrorBoundary(props) {
  return (
    <div className='text-center mt-5 text-xl text-primary-700 dark:text-primary-200'>
      Oops.... <br />
      <span className='opacity-75'>Page Not Found</span>
    </div>
  );
}
