import React from 'react';
import PgAddForm from '../../../components/common/forms/pg-add-form/PgAddForm';

export default function AddPG() {
  return (
    <div className='add-device'>
      <div className=' max-w-xl mx-auto'>
        <PgAddForm />
      </div>
    </div>
  );
}
