import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CUSTOMER_ADMIN, CUSTOMER_USER, getCompanies, getTickets, SUPER_ADMIN } from '../../../apis/apis';
import Modal from '../../../components/basic/Modals/modal with create Portal/Modal';
import SelectFromOption from '../../../components/basic/SelectedInput/SelectedInput';
import ModalDelConfirmation from '../../../components/common/modal-del-confirmation';
import NoData from '../../../components/common/no-data/NoData';
import { PaginatedItems } from '../../../components/common/pagination/Paginate';
import Suspender from '../../../components/common/suspender/Suspender';
import { makeFormObjectFromArray } from '../../../utils/utils';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plusIcon.svg';
import TicketAddForm from '../../../components/common/forms/ticket-add-form/TicketAddForm';

export default function TicketList() {
  const {
    user: {
      data: { id, user_type, company },
    },
  } = useSelector((state) => state.auth) || {};
  const companies = useSelector((state) => state.companies);

  const { isLoading, isError, list } = useSelector((state) => state.tickets);
  const [queryObj, updateQueryObject] = useState({ page: 0, per_page: 10 });
  const [modalItem, setModalItem] = useState(null);
  const [selCompany, setSelCompany] = useState(null);
  const [formData, updateFormData] = useState(makeFormObjectFromArray([{ fieldName: 'company_id', value: '', required: true }]));

  const handleConfirm = () => {
    // deleteZones(modalItem.id);
    setModalItem(null);
  };

  useEffect(() => {
    let copy = { ...formData };
    copy['company_id'].value = selCompany?.id || '';
    copy['company_id'].error = false;
    updateFormData({ ...copy });
    if (selCompany) {
      updateQueryObject((state) => ({ ...state, company_id: selCompany.id }));
    }
  }, [selCompany]);

  useEffect(() => {
    if (user_type === SUPER_ADMIN) {
      getCompanies();
    }
    if (user_type === CUSTOMER_ADMIN) {
      getTickets({ ...queryObj, company_id: company.id, user_id: id });
    } else if (user_type === CUSTOMER_USER) {
      getTickets({ ...queryObj, user_id: id });
    } else {
      getTickets({ ...queryObj, company_id: selCompany?.id || '', user_id: id });
    }
  }, [queryObj]);

  return (
    <Suspender isLoading={isLoading} isError={isError}>
      {modalItem && (
        <Modal onClose={() => setModalItem(null)}>
          <div className='max-w-xl max-h-[500px] overflow-y-auto p-4 rounded-md bg-primary-200 dark:bg-primary-600  mx-auto'>
            <TicketAddForm />
          </div>
        </Modal>
      )}

      <div onClick={() => setModalItem(true)} className='mb-10 w-10 ml-auto rounded-full p-2 border cursor-pointer border-primary-400'>
        {<PlusIcon />}
      </div>
      {user_type === SUPER_ADMIN && (
        <div className='mb-5'>
          <SelectFromOption
            onClick={(op) => setSelCompany(op)}
            value={selCompany || ''}
            displayKey='name'
            options={companies.list || []}
            headerClass=' border h-10 rounded-lg border-primary-300  text-primary-700 dark:text-primary-100 text-base'
            placeholder='Select Company'
            fieldError={formData.company_id?.error || false}
          />
        </div>
      )}
      <div className='tower-sites p-4'>
        <div className='table-container h-[530px] overflow-y-auto'>
          <table className='w-full text-center text-primary-dark '>
            <thead className='h-12'>
              <tr>
                <th className='px-3'>
                  <pre>Device ID</pre>
                </th>
                <th className='px-3'>
                  <pre>Zone ID</pre>
                </th>
                <th className='px-3'>
                  <pre>Company ID</pre>
                </th>
                <th className='px-3'>
                  <pre>Fault Type Id</pre>
                </th>
                <th className='px-3'>
                  <pre>Current Case Status </pre>
                </th>
                <th>
                  <pre>Case Status </pre>
                </th>
                <th className='px-3'>
                  <pre>Operational State </pre>
                </th>
                <th className='px-3'>
                  <pre>Operational State </pre>
                </th>

                <th>
                  <pre>Problem Details </pre>
                </th>
                {/* <th>
                  <pre>Action</pre>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {list.data?.map((item) => (
                <tr key={item.id}>
                  <td>{item?.device_id || '-'}</td>
                  <td>{item?.zone_id || '-'}</td>
                  <td>{item?.company_id || '-'}</td>
                  <td>{item?.fault_type_id || '-'}</td>
                  <td>{item?.current_case_state || '-'}</td>
                  <td>{item?.case_status || '-'}</td>
                  <td>{item?.operational_state || '-'}</td>
                  <td>{item?.problem_details || '-'}</td>
                  <td>
                    <div className='flex items-center justify-center gap-1 h-8'>
                      {/* <h4 className='w-12 rounded-md h-full cursor-pointer border border-[#28a745] flex items-center justify-center'>
                        Edit
                      </h4> */}
                      {/* <h4
                        onClick={() => setModalItem(item)}
                        className='w-12 rounded-md h-full cursor-pointer border border-[#dc3545] flex items-center justify-center'
                      >
                        delete
                      </h4> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {list?.data === 0 && <NoData />}
        </div>

        <div className='my-4'>
          <PaginatedItems
            itemsPerPage={queryObj.per_page}
            onClick={(parm) => updateQueryObject({ ...queryObj, page: parm })}
            totalItems={list.pagination?.total}
          />
        </div>
      </div>
    </Suspender>
  );
}
