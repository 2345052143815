import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Loader from './Loader';

export default function Suspender({ children, isLoading, isError }) {
  const [visit, setVisit] = useState(false);

  useEffect(() => {
    setVisit(true);
  }, []);

  if (isLoading && !visit) {
    return (
      <>
        <Loader />
      </>
    );
  } else if (!isLoading && isError)
    return <div className=' dark:text-primary-200 text-primary-700'>
      {/* {isError.data.error} */}
      Something wrong...
    </div>;

  return <>{children}</>;
}
