import React, { useEffect, useState } from 'react';
import './DeviceAddForm.css';

import { useSelector } from 'react-redux';
import { getCompanies, postFormData } from '../../../../apis/apis';
import Button from '../../../basic/button/Button';
import Input from '../../../basic/inputs/Input/Input';
import SelectFromOption from '../../../basic/SelectedInput/SelectedInput';
import { makeSubmittableData } from '../makeSubmittableData';
import { validation } from '../validator';
import { makeFormObjectFromArray } from '../../../../utils/utils';

export default function DeviceAddForm() {
  const companyList = useSelector((state) => state.companies);
  const [selCompany, setSelCompany] = useState({});
  const initFormData = makeFormObjectFromArray([
    { fieldName: 'device_id', required: true },
    { fieldName: 'imei', required: true },
    { fieldName: 'hardware_version' },
    { fieldName: 'software_version' },
    { fieldName: 'device_batch_no', required: true },
    { fieldName: 'company_id', required: true },
  ]);
  const [formData, updateFormData] = useState(initFormData);

  const handleChange = (fieldName, data) => {
    updateFormData({ ...formData, [fieldName]: data });
  };
  const resetter = () => {
    updateFormData(initFormData);
    setSelCompany({});
  };
  const handleSubmit = async () => {
    let data = makeSubmittableData({
      ...formData,
    });
    if (data.status) {
      await postFormData(data.data, 'think-device/create', resetter);
    } else {
      updateFormData({ ...data.data });
    }
  };

  //for dropdown to catch changed value
  useEffect(() => {
    let copy = { ...formData };
    copy['company_id'].value = selCompany.id;
    copy['company_id'].error = false;
    updateFormData({ ...copy });
  }, [selCompany]);

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <div className='device-add-form w-full h-full'>
      <div className='mb-5'>
        <Input
          name='device_id'
          validation={validation.required}
          value={formData.device_id.value}
          onChange={handleChange}
          placeholder='Type Device Id'
          className='custom-input'
          type='text'
          fieldError={formData.device_id?.error || false}
        />
      </div>

      <div className='mb-5'>
        <Input
          name='imei'
          validation={validation.required}
          value={formData.imei?.value}
          onChange={handleChange}
          placeholder='Type IMEI'
          className='custom-input'
          type='text'
          fieldError={formData.imei?.error || false}
        />
      </div>
      <div className='mb-5'>
        <Input
          name='hardware_version'
          value={formData.hardware_version?.value}
          onChange={handleChange}
          placeholder='Type Hardware Version'
          className='custom-input'
          type='text'
          fieldError={formData.hardware_version?.error || false}
        />
      </div>

      <div className='mb-5'>
        <Input
          name='software_version'
          value={formData.software_version?.value}
          onChange={handleChange}
          placeholder='Type Software Version'
          className='custom-input'
          type='text'
          fieldError={formData.software_version?.error || false}
        />
      </div>

      <div className='mb-5'>
        <Input
          name='device_batch_no'
          value={formData.device_batch_no?.value}
          validation={validation.required}
          onChange={handleChange}
          placeholder='Type Device Batch No'
          className='custom-input'
          type='text'
          fieldError={formData.device_batch_no?.error || false}
        />
      </div>

      <div className='mb-5'>
        <SelectFromOption
          onClick={(op) => setSelCompany(op)}
          value={selCompany}
          options={companyList.list || []}
          displayKey='name'
          headerClass='header-class text-[13px]'
          placeholder='Select Company'
          fieldError={formData.company_id?.error || false}
        />
      </div>

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass h-10 btn-normal' title='Create' />
      </div>
    </div>
  );
}
