import React from 'react';

export default function MiniNav({ title = '' }) {
  return (
    <div className='mb-10 flex items-center justify-between'>
      <h3 className='text-primary'>{title}</h3>
      <i style={{ fontSize: '14px', marginLeft: '6px' }} className='fa fa-caret-down '></i>
    </div>
  );
}
