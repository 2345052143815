import React from 'react';
import Progress from '../../../../../components/basic/progress/Progress';
import { fillByColor, getFormattedServiceHours, timeConvert } from '../../../../../utils/utils';

const data = [
  { id: 1, title1: '130 Hours', title2: 'TH00369', bg: '#D2E8F6', bgBar: '#0C9EE1' },
  { id: 2, title1: '110 Hours', title2: 'TH00363', bg: '#EFE8F9', bgBar: '#A67EF8' },
  { id: 3, title1: '80 Hours', title2: 'TH002563', bg: '#FCF2D5', bgBar: '#EFBC26' },
  { id: 4, title1: '70 Hours', title2: 'TH128769', bg: '#E8EBD6', bgBar: '#7FA63C' },
];

export default function TopSites(props) {
  const sec = Number(props.data[0]?.run_hour);
  const divider = sec + sec * 0.1;
  return (
    <div className='top-sites w-full h-[270px] overflow-auto '>
      {props.data?.map((item, i) => (
        <div key={item.code} className='mb-8'>
          <Progress
            height='6px'
            bgColor={fillByColor(11 % i).bgColor}
            barColor={fillByColor(11 % i).color}
            brRadius='2px'
            current={Math.floor((item.run_hour / divider) * 100)}
          >
            <div className='w-full flex'>
              <h3 className=''>{getFormattedServiceHours(item.run_hour)}</h3>
              <h3 className=' ml-auto'>{item.code}</h3>
            </div>
          </Progress>
        </div>
      ))}
    </div>
  );
}
