import { getFormattedServiceHours } from '../../../../../../utils/utils';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className='w-[200px] p-3 bg-primary-100 text-primary-600 outline-none'>
        <h3 className=''>{label}</h3>
        <h5>Run Hour</h5>
        <h5 className=''>{getFormattedServiceHours(payload[0].payload.run_hour)}</h5>
      </div>
    );
  }

  return null;
};
export default CustomTooltip;
