import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Button from '../../components/basic/button';
import Search from '../../components/basic/search-input/Search';
import SelectFromOption from '../../components/basic/SelectedInput/SelectedInput';
import LMap from '../../components/common/leaflet-map/LMap';
import { checkEmpty, xlsxFileReader } from '../../utils/utils';
import './FilterOnMap.css';

export default function FilterOnMap() {
  const [formDataAsArr, updateFormDataAsArr] = useState([]);
  const [initData, updateInitData] = useState({});
  const [filteredData, updateFilteredData] = useState({ errors: null, oks: null, duplicates: null });
  const [filterObj, updateFilterObj] = useState({});
  const [options, updateOptions] = useState({ codes: [], tempKeys: [], districts: [], regions: [] });

  useEffect(() => {
    let key = Object.keys(filterObj)[1];
    if (key) {
      const copyData = { ...initData };
      const tempArr = [...copyData.oks]?.filter((it) => it[key] === filterObj[key]);
      const { oks, duplicates, errors } = splitArrayToError(tempArr);
      updateFilteredData((state) => ({ ...state, oks, duplicates, errors }));
    } else {
      updateFilteredData(initData);
    }
  }, [filterObj, initData]);

  const handleSubmit = async () => {
    const { oks, duplicates, errors } = splitArrayToError(formDataAsArr);
    updateInitData({ oks, duplicates, errors });
    const codes = new Set([...oks?.map((it, i) => it.code)]);
    const tempKeys = new Set([...oks?.map((it, i) => it.tempKey)]);
    const districts = new Set([...oks?.map((it, i) => it.district)]);
    const regions = new Set([...oks?.map((it, i) => it.region)]);
    updateOptions((state) => ({ ...state, tempKeys: [...tempKeys], codes: [...codes], districts: [...districts], regions: [...regions] }));
  };

  const readUploadFile = async (e) => {
    if (e.target.files) {
      const res = await xlsxFileReader(e.target.files[0], [
        'name',
        'code',
        'address',
        'latitude',
        'longitude',
        'site_ref',
        'thana',
        'district',
        'region',
        'category',
      ]);
      updateFormDataAsArr(res);
    }
  };

  const handleFilterClick = (item) => {
    updateFilterObj(item);
  };

  const handleSearchClick = (value) => {
    if (value && initData.oks) {
      const copyData = { ...initData };
      const tempArr = [...copyData.oks]?.filter(
        (it) => it['code'].toString().startsWith(value) || it['code'].toString().startsWith(value.toUpperCase())
      );
      const { oks, duplicates, errors } = splitArrayToError(tempArr);
      updateFilteredData((state) => ({ ...state, oks, duplicates, errors }));
    }
  };

  return (
    <div className='filter-on-map'>
      <div className='mb-5'>
        <input onChange={(e) => readUploadFile(e)} type='file' />
      </div>

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass h-10 btn-normal' title='Upload' />
      </div>

      <Search icon onClick={handleSearchClick} placeholder='Search by code' className='mr-2 cursor-pointer custom-search hidden lg:inline' />

      <div className='my-4 filter grid grid-flow-row grid-cols-12 gap-2'>
        <div className='grid-item'>
          <SelectFromOption
            headerClass='header-class h-8  rounded-sm  py-2'
            options={options.codes?.map((it, i) => ({ id: i + 1, code: it })) || []}
            value={filterObj}
            displayKey='code'
            onClick={handleFilterClick}
            placeholder='Select Code'
            readOnly={false}
          />
        </div>
        <div className='grid-item col'>
          <SelectFromOption
            headerClass='header-class h-8  rounded-sm  py-2'
            options={options.tempKeys?.map((it, i) => ({ id: i + 1, tempKey: it })) || []}
            value={filterObj}
            displayKey='tempKey'
            onClick={handleFilterClick}
            placeholder='Select Thana'
            readOnly={false}
          />
        </div>
        <div className='grid-item'>
          <SelectFromOption
            headerClass='header-class h-8  rounded-sm  py-2'
            options={options.districts?.map((it, i) => ({ id: i + 1, district: it })) || []}
            value={filterObj}
            displayKey='district'
            onClick={handleFilterClick}
            placeholder='Select District'
            readOnly={false}
          />
        </div>
        <div className='grid-item'>
          <SelectFromOption
            headerClass='header-class h-8  rounded-sm  py-2'
            options={options.regions?.map((it, i) => ({ id: i + 1, region: it })) || []}
            value={filterObj}
            displayKey='region'
            onClick={handleFilterClick}
            placeholder='Select region'
            readOnly={false}
          />
        </div>
      </div>

      <div className='flex flex-col xl:flex-row gap-2'>
        <div className='flex-1'>
          <LMap zoom={8} data={filteredData.oks || []} />
        </div>
        <div className='flex flex-col w-max shadow-light dark:shadow-dark p-4'>
          {!checkEmpty(filteredData.errors) && (
            <div className=' w-full overflow-auto'>
              <h1 className='text-xl my-4'>Error:</h1>
              <table>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>
                      <pre>Lat</pre>
                    </th>
                    <th>
                      <pre>Long</pre>
                    </th>
                    <th>
                      <pre>remarks</pre>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.errors?.map((it) => (
                    <tr key={it.code}>
                      <td className='whitespace-nowrap px-2  '>{it.code || '-'}</td>
                      <td className='whitespace-nowrap px-2 '>{it.latitude || '-'}</td>
                      <td className='whitespace-nowrap px-2 '>{it.longitude || '-'}</td>
                      <td className='whitespace-nowrap px-2 text-[#c63939]'>{it.remarks?.map((rem, i) => <p key={i}>{rem}</p>) || '-'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {!checkEmpty(filteredData.duplicates) && (
            <div className='my-2 overflow-auto'>
              <h1 className='text-xl my-4'>Duplicate:</h1>
              <table>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>
                      <pre>Lat</pre>
                    </th>
                    <th>
                      <pre>Long</pre>
                    </th>
                    <th>
                      <pre>Count</pre>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.duplicates?.map((it) => (
                    <tr key={it.code}>
                      <td className='whitespace-nowrap px-2  '>{it.code || '-'}</td>
                      <td className='whitespace-nowrap px-2 '>{it.latitude || '-'}</td>
                      <td className='whitespace-nowrap px-2 '>{it.longitude || '-'}</td>
                      <td className='whitespace-nowrap px-2 '>{it.count || '-'} times</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function splitArrayToError(dataArr) {
  let errorArray = [];
  let finalArray = [];
  const duplicates = [];

  dataArr?.forEach((item) => {
    let lan = item.latitude?.toString().trim(' ') || null;
    let long = item.longitude?.toString().trim(' ') || null;
    let code = item.code?.toString().trim(' ') || null;
    let remarks = [];
    if (['', null, undefined].includes(code)) {
      remarks.push(['latitude is  null']);
    }
    if (['', null, undefined].includes(lan)) {
      remarks.push(['latitude is  null']);
    }
    if (['', null, undefined].includes(long)) {
      remarks.push(['longitude is null']);
    }

    if (lan?.split(' ').length !== 1) {
      remarks.push(['Space in latitude']);
    }
    if (long?.split(' ').length !== 1) {
      remarks.push(['Space in longitude']);
    }

    if (!remarks.length) {
      finalArray.push({ ...item, tempKey: `${item.thana}, ${item.district}` });
    } else {
      errorArray.push({ ...item, remarks: [...remarks] });
    }
  });

  const mapObj = new Map();
  finalArray?.forEach((it) => {
    const key = it.code;
    if (mapObj.has(key)) {
      mapObj.set(key, { ...it, count: mapObj.get(key).count + 1 });
    } else {
      mapObj.set(key, { ...it, count: 1 });
    }
  });

  for (const item of mapObj) {
    const value = item[1];
    if (value.count > 1) duplicates.push(value);
  }
  return { errors: errorArray, oks: finalArray, duplicates: duplicates };
}
