import { createSlice } from '@reduxjs/toolkit';

const activeDevices = createSlice({
  name: 'activeDevices',
  initialState: { isLoading: false, list: [], isError: false },
  reducers: {
    activeDevicesInit: (state) => {
      return { ...state, isLoading: true };
    },
    activeDevicesSuccess: (state, action) => {
      return { ...state, list: action.payload.data, isLoading: false };
    },
    activeDevicesFail: (state, action) => {
      return { ...state, list: [], isLoading: false, isError: action.payload.data };
    },

    activeDevicesDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    activeDevicesDelSuccess: (state, action) => {
      return {
        ...state,
        list: state.list.filter((item) => !action.payload.data.includes(item.device_id)),
        isLoading: false,
      };
    },
    activeDevicesDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const {
  activeDevicesInit,
  activeDevicesSuccess,
  activeDevicesFail,
  activeDevicesDelInit,
  activeDevicesDelSuccess,
  activeDevicesDelFail,
} = activeDevices.actions;
export default activeDevices.reducer;
