import React, { useState } from 'react';
import { userPasswordResetGetLink } from '../../../apis/apis';
import Button from '../../../components/basic/button/Button';
import Input from '../../../components/basic/inputs/Input/Input';
import { makeSubmittableData } from '../../../components/common/forms/makeSubmittableData';
import { validation } from '../../../components/common/forms/validator';

export default function PasswordResetIndex() {
  const [formData, updateFormData] = useState({
    email: { value: '', required: true },
  });

  //   const navigate = useNavigate();

  const handleChange = (fieldName, data) => {
    updateFormData({ ...formData, [fieldName]: data });
  };

  const handleSubmit = async () => {
    let data = makeSubmittableData({
      ...formData,
    });
    if (data.status) {
      const response = await userPasswordResetGetLink(data.data);
      console.log(response);

      if (response.data.status === true) alert(`${response.data.message}`);
      else alert(`${response.data.data.error}`);
    } else {
      updateFormData({ ...data.data });
    }
  };

  //for dropdown to catch changed value

  return (
    <div className='user-password-reset mt-10 max-w-xl mx-auto h-full'>
      <div className='mb-5'>
        <Input
          name='email'
          onChange={handleChange}
          validation={validation.email}
          placeholder='Type Your email'
          className='custom-input'
          type='text'
          fieldError={formData.email?.error || false}
        />
      </div>
      {/* <div className='mb-5'>
        <Input
          name='last_name'
          value={formData.last_name.value}
          onChange={handleChange}
          placeholder='Type Your Last Name'
          className='custom-input'
          type='text'
        />
      </div> */}

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass h-10 btn-normal' title='Next' />
      </div>
    </div>
  );
}
