import React from 'react';
import './MassageBox.css';

export default function MassageBox({ pos = '', style = {} || null, children }) {
  return (
    <div
      style={style}
      className={`text-box ${
        pos === 'right' ? 'after:left-full after:-translate-x-3/4' : 'after:right-full after:translate-x-3/4'
      }`}
    >
      {children}
    </div>
  );
}
