import React from 'react';

export default function Switch({ className, value = false, onClick = () => {} }) {
  return (
    <div
      onClick={onClick}
      className={`transition-all duration-300 p-2 relative rounded-lg ${className} ${value ? 'bg-secondary-800  justify-end' : 'bg-primary-200'}`}
    >
      <div
        className={`w-2/5 absolute left-0.5 top-1/2 -translate-y-1/2 aspect-square transition-all duration-300 rounded-full bg-primary-100 ${
          value && 'left-[calc(50%-2px)]'
        }`}
      ></div>
    </div>
  );
}
