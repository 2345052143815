import React, { useEffect, useState } from 'react';
import './TicketAddForm.css';

import { useSelector } from 'react-redux';
import { CUSTOMER_ADMIN, getCompanies, getFaultTypes, getZones, postFormData, SUPER_ADMIN } from '../../../../apis/apis';
import Button from '../../../basic/button/Button';
import Input from '../../../basic/inputs/Input/Input';
import SelectFromOption from '../../../basic/SelectedInput/SelectedInput';
import { makeSubmittableData } from '../makeSubmittableData';
import { validation } from '../validator';
import { makeFormObjectFromArray } from '../../../../utils/utils';
import Textarea from '../../../basic/text-area/Textarea';
import CheckBox from '../../../basic/check-box/CheckBox';

export default function TicketAddForm() {
  const {
    user: {
      data: { id, user_type, company },
    },
  } = useSelector((state) => state.auth) || {};

  const companyList = useSelector((state) => state.companies);
  const zones = useSelector((state) => state.zones);
  const faultTypes = useSelector((state) => state.faultTypes);

  const [selCompany, setSelCompany] = useState(null);
  const [selZone, setSelZone] = useState(null);
  const [selFaultType, setSelFaultType] = useState(null);

  const initFormData = makeFormObjectFromArray([
    { fieldName: 'device_id', required: true },
    { fieldName: 'fault_type_id', required: true },
    { fieldName: 'company_id', required: true },
    { fieldName: 'zone_contact_number', required: true },
    { fieldName: 'zone_id', required: true },
    { fieldName: 'operational_state', value: false },
    { fieldName: 'creator_id', required: true, value: id },
    { fieldName: 'problem_details', required: true },
    { fieldName: 'file' },
  ]);
  const [formData, updateFormData] = useState(initFormData);

  const handleChange = (fieldName, data) => {
    updateFormData({ ...formData, [fieldName]: data });
  };
  const resetter = () => {
    updateFormData(initFormData);
    setSelCompany(null);
    setSelZone(null);
    setSelFaultType(null);
  };

  const handleSubmit = async () => {
    let data = makeSubmittableData({
      ...formData,
    });
    console.log(data);
    if (data.status) {
      await postFormData(data.data, 'support-case/create', resetter);
    } else {
      updateFormData({ ...data.data });
    }
  };

  const handleOperationalState = () => {
    let copy = { ...formData };
    copy['operational_state'].value = !copy.operational_state?.value || false;
    copy['operational_state'].error = false;
    updateFormData({ ...copy });
  };

  //for dropdown to catch changed value
  useEffect(() => {
    let copy = { ...formData };
    copy['company_id'].value = user_type === CUSTOMER_ADMIN ? company.id : selCompany ? selCompany.id : '';
    copy['company_id'].error = false;
    updateFormData({ ...copy });
  }, [selCompany]);

  useEffect(() => {
    let copy = { ...formData };
    copy['zone_id'].value = selZone?.id || '';
    copy['zone_id'].error = false;
    updateFormData({ ...copy });
  }, [selZone]);

  useEffect(() => {
    let copy = { ...formData };
    copy['fault_type_id'].value = selFaultType?.id || '';
    copy['fault_type_id'].error = false;
    updateFormData({ ...copy });
  }, [selFaultType]);

  useEffect(() => {
    if (user_type === SUPER_ADMIN) {
      getCompanies();
    }
    if (user_type === CUSTOMER_ADMIN) {
      setSelCompany({ ...company });
    }
    getZones({ page: 0, per_page: 1000 });
    getFaultTypes({ page: 0, per_page: 1000 });
  }, []);

  return (
    <div className='ticket-add-form w-full h-full'>
      <div className='mb-5'>
        <Input
          name='device_id'
          validation={validation.required}
          value={formData.device_id.value}
          onChange={handleChange}
          placeholder='Type Device Id'
          className='custom-input'
          type='text'
          fieldError={formData.device_id?.error || false}
        />
      </div>
      <div className='mb-5'>
        <SelectFromOption
          onClick={(op) => setSelFaultType(op)}
          value={selFaultType || ''}
          options={faultTypes?.list?.data || []}
          displayKey='name'
          headerClass='header-class text-[13px]'
          placeholder='Select Fault Type'
          fieldError={formData.fault_type_id?.error || false}
        />
      </div>

      <div className='mb-5'>
        <SelectFromOption
          onClick={(op) => setSelCompany(op)}
          value={selCompany || ''}
          options={companyList?.list || []}
          displayKey='name'
          headerClass='header-class text-[13px]'
          placeholder='Select Company'
          fieldError={formData.company_id?.error || false}
        />
      </div>
      <div className='mb-5'>
        <SelectFromOption
          onClick={(op) => setSelZone(op)}
          value={selZone || ''}
          options={zones?.list?.data || []}
          displayKey='name'
          headerClass='header-class text-[13px]'
          placeholder='Select Zone'
          fieldError={formData.zone_id?.error || false}
        />
      </div>
      <div className='mb-5'>
        <Input
          name='zone_contact_number'
          validation={validation.required}
          value={formData.zone_contact_number?.value}
          onChange={handleChange}
          placeholder='Type zone_contact_number'
          className='custom-input'
          type='text'
          fieldError={formData.zone_contact_number?.error || false}
        />
      </div>

      <div className='mb-10'>
        <CheckBox onClick={handleOperationalState}>
          <label className='ml-2 text-primary-400 dark:text-primary-300'>Operational State</label>
        </CheckBox>
      </div>
      <div className='mb-5'>
        <Textarea
          name='problem_details'
          value={formData.problem_details?.value}
          onChange={handleChange}
          placeholder='Type Problem Details'
          className='custom-text-input'
          type='text'
          fieldError={formData.problem_details?.error || false}
        />
      </div>

      <div className='mb-5'>
        <Input
          name='file'
          validation={validation.required}
          value={formData?.file?.value || ''}
          onChange={handleChange}
          placeholder='Choose a File'
          className='custom-input'
          type='file'
          fieldError={formData.file?.error || false}
        />
      </div>

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass h-10 btn-normal' title='Create' />
      </div>
    </div>
  );
}
