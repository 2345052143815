import React, { useEffect, useState } from 'react';
import './CreateCompanyForm.css';
import { useSelector } from 'react-redux';
import { getCountries, getTowerCompanies, postFormData } from '../../../../apis/apis';
import Button from '../../../basic/button/Button';
import Input from '../../../basic/inputs/Input/Input';
import SelectFromOption from '../../../basic/SelectedInput/SelectedInput';
import { makeSubmittableData } from '../makeSubmittableData';
import { validation } from '../validator';
import { makeFormObjectFromArray } from '../../../../utils/utils';

export default function CreateCompanyForm({ formAsUpdate = false }) {
  const countryList = useSelector((state) => state.countries);
  const towerCompanies = useSelector((state) => state.towerCompanies);
  const [selCountry, setSelCountry] = useState({});
  const [selTowerCompany, setSelTowerCompany] = useState({});
  const initFormData = makeFormObjectFromArray([
    { fieldName: 'name', value: '', required: true },
    { fieldName: 'phone', value: '', required: true },
    { fieldName: 'email', value: '', required: true },
    { fieldName: 'country_id', value: '', required: true },
    { fieldName: 'tower_company_id', value: '', required: true },
  ]);
  const [formData, updateFormData] = useState(initFormData);

  const handleChange = (fieldName, data) => {
    updateFormData({ ...formData, [fieldName]: data });
  };

  const resetter = () => {
    updateFormData(initFormData);
    setSelCountry({});
    setSelTowerCompany({});
  };

  const handleSubmit = async () => {
    let data = makeSubmittableData({
      ...formData,
    });
    if (data.status) {
      await postFormData(data.data, 'company/create', resetter);
    } else {
      updateFormData({ ...data.data });
    }
  };

  //for dropdown to catch changed value
  useEffect(() => {
    let copy = { ...formData };
    copy['country_id'].value = selCountry.id;
    copy['country_id'].error = false;
    copy['tower_company_id'].value = selTowerCompany.id;
    copy['tower_company_id'].error = false;
    updateFormData({ ...copy });
  }, [selCountry, selTowerCompany]);

  useEffect(() => {
    getCountries();
    getTowerCompanies();
  }, []);

  return (
    <div className='create-company-form w-full h-full'>
      <div className='mb-5'>
        <Input
          name='name'
          value={formData.name?.value}
          onChange={handleChange}
          validation={validation.required}
          placeholder='Type Company Name'
          className='custom-input'
          type='text'
          fieldError={formData.name?.error || false}
        />
      </div>

      <div className='mb-5'>
        <Input
          name='email'
          value={formData.email.value}
          onChange={handleChange}
          validation={validation.email}
          placeholder='Type Company Email'
          className='custom-input'
          type='email'
          fieldError={formData.email?.error || false}
        />
      </div>
      <div className='mb-5'>
        <Input
          name='phone'
          value={formData.phone?.value}
          onChange={handleChange}
          validation={validation.phone}
          placeholder='Type Phone Number'
          className='custom-input'
          type='text'
          fieldError={formData.phone?.error || false}
        />
      </div>

      <div className='mb-5'>
        <SelectFromOption
          onClick={(op) => setSelCountry(op)}
          options={countryList.list || []}
          value={selCountry}
          headerClass='header-class'
          placeholder='Select Country'
          fieldError={formData.country_id?.error || false}
        />
      </div>
      <div className='mb-5'>
        <SelectFromOption
          onClick={(op) => setSelTowerCompany(op)}
          options={towerCompanies.list || []}
          value={selTowerCompany}
          displayKey='full_name'
          headerClass='header-class text-[13px]'
          placeholder='Select Tower Company'
          fieldError={formData.tower_company_id?.error || false}
        />
      </div>

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass h-10 btn-normal' title='Create' />
      </div>
    </div>
  );
}
