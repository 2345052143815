import React, { useEffect } from 'react';
import { AreaChart, Area, Tooltip, XAxis, YAxis, ResponsiveContainer, LineChart, CartesianGrid, Legend, Line } from 'recharts';
import './style.css';

const data = [
  {
    id: 1,
    fuel: 100,
    standard: 50,
  },
  {
    id: 2,
    fuel: 40,
    standard: 50,
  },
  {
    id: 3,
    fuel: 70,
    standard: 50,
  },
  {
    id: 4,
    fuel: 30,
    standard: 50,
  },
];

export default function PowerFactorChart({ options = {}, children, headerClass = '', className = '' }) {
  return (
    <div className={`power-factor-chart ${className}`}>
      <div className={headerClass}>{children}</div>
      <div className='chart-container'>
        <ResponsiveContainer height={100}>
          <LineChart width={500} height={300} data={data} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
            {/* <CartesianGrid strokeDasharray='3 3' /> */}
            <XAxis dataKey='id' />
            <YAxis dataKey='fuel' />

            <Tooltip />
            <Line type='monotone' dataKey='fuel' stroke='#8D88A2' dot={false} />
            <Line type='monotone' dataKey='standard' stroke='#FFFFFF' dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

// import React, { PureComponent } from 'react';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// export default class Example extends PureComponent {
//   static demoUrl = 'https://codesandbox.io/s/simple-line-chart-kec3v';

//   render() {
//     return (
//       <ResponsiveContainer width="100%" height="100%">
//         <LineChart
//           width={500}
//           height={300}
//           data={data}
//           margin={{
//             top: 5,
//             right: 30,
//             left: 20,
//             bottom: 5,
//           }}
//         >
//           <CartesianGrid strokeDasharray="3 3" />
//           <XAxis dataKey="name" />
//           <YAxis />
//           <Tooltip />
//           <Legend />
//           <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
//           <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
//         </LineChart>
//       </ResponsiveContainer>
//     );
//   }
// }
