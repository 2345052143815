import React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { credentials, userPasswordReset } from '../../../apis/apis';
import Button from '../../../components/basic/button/Button';
import Input from '../../../components/basic/inputs/Input/Input';
import { makeSubmittableData } from '../../../components/common/forms/makeSubmittableData';
import { validation } from '../../../components/common/forms/validator';

export default function PasswordResetForm() {
  const { token, email } = useParams();
  const navigate = useNavigate();

  const [formData, updateFormData] = useState({
    token: { value: token, required: true },
    email: { value: email, required: true },
    password: { value: '', required: true },
  });

  //   const navigate = useNavigate();

  const handleChange = (fieldName, data) => {
    updateFormData({ ...formData, [fieldName]: data });
  };

  const handleSubmit = async () => {
    let data = makeSubmittableData({
      ...formData,
    });

    if (data.status) {
      const response = await userPasswordReset(data.data);
      if (response.data.status === true) {
        credentials.setTokens({ token: response.data.token, refreshToken: response.data.refreshToken });
        alert(`Password reset Successfully`);
        navigate('/');
      } else alert(`${response.data.data.error}`);
    } else {
      updateFormData({ ...data.data });
    }
  };

  //for dropdown to catch changed value

  return (
    <div className='user-update mt-10 max-w-xl mx-auto h-full'>
      <div className='mb-5'>
        <Input
          name='password'
          onChange={handleChange}
          validation={validation.password}
          placeholder='Type Your new password'
          className='custom-input'
          type='text'
          fieldError={formData.password?.error || false}
        />
      </div>

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass btn-success h-10' title='Rest Password' />
      </div>
    </div>
  );
}
