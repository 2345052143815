import { configureStore } from '@reduxjs/toolkit';
import authSlice from '../slices/auth';
import companiesSlice from '../slices/companies';
import countriesSlice from '../slices/countries';
import towerSites from '../slices/tower-sites';
import userTypeSlice from '../slices/user-types';
import usersSlice from '../slices/users';
import towerCompaniesSlice from '../slices/tower-companies';
import devices from '../slices/devices';
import pgs from '../slices/pgs';
import userPgs from '../slices/user-pgs';
import deviceLogs from '../slices/device-logs';
import reports from '../slices/reports';
import dashboard from '../slices/home-dash';
import activeDevices from '../slices/active-devices';
import zones from '../slices/zones';
import tickets from '../slices/tickets';
import faultTypes from '../slices/fault-types';

export const store = configureStore({
  reducer: {
    tickets: tickets,
    zones: zones,
    faultTypes: faultTypes,
    activeDevices: activeDevices,
    dashboard: dashboard,
    reports: reports,
    deviceLogs: deviceLogs,
    userPgs: userPgs,
    pgs: pgs,
    devices: devices,
    countries: countriesSlice,
    towerCompanies: towerCompaniesSlice,
    users: usersSlice,
    companies: companiesSlice,
    towerSites,
    userTypes: userTypeSlice,
    auth: authSlice,
  },
});
