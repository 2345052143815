import { createSlice } from '@reduxjs/toolkit';

const faultTypesSlice = createSlice({
  name: 'faultTypes',
  initialState: { isLoading: false, list: [], isError: false },
  reducers: {
    faultTypesInit: (state) => {
      return { ...state, isLoading: true };
    },
    faultTypesSuccess: (state, action) => {
      return { ...state, list: action.payload.data, isLoading: false };
    },
    faultTypesFail: (state, action) => {
      return { ...state, list: [], isLoading: false, isError: action.payload.data };
    },
    faultTypesDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    faultTypesDelSuccess: (state, action) => {
      return {
        ...state,
        list: { ...state.list, data: state.list.data.filter((item) => !action.payload.data.includes(item.id)) },
        isLoading: false,
      };
    },
    faultTypesDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { faultTypesInit, faultTypesSuccess, faultTypesFail, faultTypesDelInit, faultTypesDelSuccess, faultTypesDelFail } =
  faultTypesSlice.actions;
export default faultTypesSlice.reducer;
