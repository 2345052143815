import React, { useState } from 'react';
import './style.css';
import { xlsxFileReader } from '../../../utils/utils';
import { postDataFromFile } from '../../../apis/apis';
import Button from '../../../components/basic/button';

export default function PGFileUpload() {
  const [formDataAsArr, updateFormDataAsArr] = useState([]);
  const [trackObj, updateTrackObj] = useState({});

  const handleSubmit = async () => {
    const result = await postDataFromFile(formDataAsArr, 'pg-name/create');
    updateTrackObj({ ...trackObj, ...result });
    updateFormDataAsArr([]);
  };

  const readUploadFile = async (e) => {
    if (e.target.files) {
      const res = await xlsxFileReader(e.target.files[0], ['pg_number', 'pg_name', 'address', 'city', 'company_id']);
      updateFormDataAsArr(res);
    }
  };

  return (
    <div className='tower-site-file-upload p-4'>
      <div className='mb-5'>
        <input onChange={(e) => readUploadFile(e)} type='file' />
      </div>

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass h-10 btn-normal' title='Upload' />
      </div>

      <div className='response-data overflow-auto'>
        {trackObj?.duplicate?.length > 0 && (
          <p className='text-primary-light'>
            <span className='text-sm text-lime-500'>{trackObj.duplicate.join(',')}</span>
            <br /> {trackObj.duplicate.length} tower sites are already exist
          </p>
        )}
        {trackObj?.newCreate?.length > 0 && (
          <p className='text-primary-light'>
            {trackObj.newCreate?.length} <span className='text-sm text-lime-500'>are newly added successfully</span>{' '}
          </p>
        )}
        {trackObj?.err && <p className='text-red-500'>{trackObj.err}</p>}
      </div>
    </div>
  );
}
