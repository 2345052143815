import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteUser, getUsers, SUPER_ADMIN, updateUserFromUsers } from '../../../apis/apis';
import Modal from '../../../components/basic/Modals/modal with create Portal/Modal';
import Switch from '../../../components/basic/switch/Switch';
import ModalDelConfirmation from '../../../components/common/modal-del-confirmation';
import { PaginatedItems } from '../../../components/common/pagination/Paginate';
import Suspender from '../../../components/common/suspender/Suspender';
import { fullName } from '../../../utils/utils';
import './style.css';

export default function UserList() {
  const { isLoading, isError, list } = useSelector((state) => state.users);
  const { user = {} } = useSelector((state) => state.auth);

  const [modalItem, setModalItem] = useState(null);
  const navigate = useNavigate();

  const handleConfirm = () => {
    deleteUser(modalItem.id);
    setModalItem(null);
  };
  const handleClickOnSwitch = (userObj = {}) => {
    updateUserFromUsers({ ...userObj });
  };

  const { pagination, data } = list || {};
  const userList = data || [];
  const [queryObj, updateQueryObject] = useState({ page: 0, per_page: 15 });

  useEffect(() => {
    if (user.data.user_type === SUPER_ADMIN) {
      getUsers(queryObj);
    } else {
      getUsers({ ...queryObj, company_id: user.data.company.id });
    }
  }, [queryObj]);

  return (
    <Suspender isLoading={isLoading} isError={isError}>
      {modalItem && (
        <Modal onClose={() => setModalItem(null)}>
          <ModalDelConfirmation onCancel={() => setModalItem(null)} onConfirm={handleConfirm} />
        </Modal>
      )}
      <div className='userList p-4'>
        <div className='table-container h-[530px] overflow-y-auto'>
          <table className='w-full text-center text-primary-dark '>
            <thead className='h-12 '>
              <tr>
                <th>
                  <pre>User Id</pre>
                </th>
                <th>
                  <pre>Name</pre>
                </th>
                <th>
                  <pre>User Email</pre>
                </th>
                <th>
                  <pre>User Type</pre>
                </th>
                <th>
                  <pre className='px-4'>PG List</pre>
                </th>
                <th>
                  <pre>Status</pre>
                </th>
                <th>
                  <pre>Action</pre>
                </th>
              </tr>
            </thead>
            <tbody>
              {userList?.map((data) => (
                <tr key={data.id}>
                  <td className=''>
                    <span className='w-2 h-2 mr-2 mb-[1px] rounded-full md:inline-block'></span>
                    {data.id}
                  </td>
                  <td>{data.first_name && data.last_name && fullName(data.first_name, data.last_name)}</td>
                  <td>{data.email}</td>
                  <td>{data.user_type?.name}</td>
                  <td>
                    {data.user_type?.id === 3 && (
                      <h4
                        onClick={() => navigate(`../pg/list/${data.id}`)}
                        className='mx-auto w-12 rounded-md h-6 cursor-pointer border border-secondary-400 flex items-center justify-center'
                      >
                        view
                      </h4>
                    )}
                  </td>
                  <td>{data.status === 1 ? 'active' : 'inactive'}</td>
                  <td>
                    <div className='flex items-center justify-center gap-1 h-8'>
                      <Switch
                        className='w-8 h-4'
                        value={data.status}
                        onClick={() => handleClickOnSwitch({ user_id: data.id, status: data.status ? 0 : 1 })}
                      />
                      <h4
                        onClick={() => setModalItem(data)}
                        className='w-12 rounded-md h-full cursor-pointer border border-[#dc3545] flex items-center justify-center'
                      >
                        delete
                      </h4>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className='my-4'>
          <PaginatedItems
            itemsPerPage={queryObj.per_page}
            onClick={(parm) => updateQueryObject({ ...queryObj, page: parm })}
            totalItems={pagination?.total}
          />
        </div>
      </div>
    </Suspender>
  );
}

// const tableData = [
//   { id: 1, siteName: 'Site A', totalTime: { hours: 1, min: 20 }, totalPower: 'kva1' },
//   { id: 2, siteName: 'Site B', totalTime: { hours: 0, min: 50 }, totalPower: 'kva2' },
//   { id: 3, siteName: 'Site C', totalTime: { hours: 2, min: 40 }, totalPower: 'kva3' },
//   { id: 4, siteName: 'Site D', totalTime: { hours: 1, min: 8 }, totalPower: 'kva4' },
//   { id: 5, siteName: 'Site E', totalTime: { hours: 0, min: 10 }, totalPower: 'kva5' },
//   { id: 6, siteName: 'Site F', totalTime: { hours: 0, min: 12 }, totalPower: 'Kva6' },
//   { id: 7, siteName: 'Site G', totalTime: { hours: 3, min: 14 }, totalPower: 'kav7' },
//   { id: 8, siteName: 'Site H', totalTime: { hours: 5, min: 15 }, totalPower: 'kva8' },
//   { id: 9, siteName: 'Site I', totalTime: { hours: 0, min: 27 }, totalPower: 'kva9' },
//   { id: 10, siteName: 'Site J', totalTime: { hours: 1, min: 10 }, totalPower: 'kva10' },
// ];
