import { ReactComponent as SearchIcon } from './searchIcon.svg';
import './search.css';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { checkEmpty } from '../../../utils/utils';

export default function Search(props) {
  const { style, placeholder, icon, children, type = 'text', className = '', onClick = () => {} } = props;
  const [inputValue, updateInputValue] = useState('');
  const inputRef = useRef();

  const handleClick = () => {
    onClick(inputValue);
  };

  return (
    <div style={style} className={`search ${className}`}>
      <input ref={inputRef} placeholder={placeholder} type={type} onChange={(e) => updateInputValue(e.target.value)} />
      {icon && <SearchIcon onClick={handleClick} className='text-primary-600 w-6' />}
      {children}
    </div>
  );
}

Search.propTypes = {
  style: PropTypes.object,
  placeholder: PropTypes.string,
  icon: PropTypes.bool.isRequired,
  children: PropTypes.element,
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
