import React, { useEffect } from 'react';
import './home.css';
import HomeTile from '../../components/common/home-title/HomeTile';
import Summaries from './components/summaries';
import QuarterSummary from './components/quarter-summary';
import CurrentActivity from './components/current-activity/CurrentActivity';
// import { gMap } from '../../components/common/google-map/gMap';
import StyleBox from '../../components/common/style-box/StyleBox';
import LMap from '../../components/common/leaflet-map/LMap';
import { CUSTOMER_ADMIN, getActiveDevices, getDashboardData, refreshTime, SUPER_ADMIN } from '../../apis/apis';
import { useState } from 'react';
import { currentQuadrant } from '../../utils/utils';
import Suspender from '../../components/common/suspender/Suspender';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';

export default function Home() {
  const {
    user: {
      data: { id, user_type, company },
    },
  } = useSelector((state) => state.auth) || {};

  const { isLoading = true, isError, list } = useSelector((state) => state.dashboard);
  const deviceFetch = useSelector((state) => state.activeDevices);
  const { data: devices = [] } = deviceFetch?.list || [];
  const actives = devices?.filter((it) => it.running === 1) || [];

  const callApiForDashboard = useCallback(
    (param) => {
      if (user_type === SUPER_ADMIN) {
        return getDashboardData({ ...param });
      } else if (user_type === CUSTOMER_ADMIN) {
        return getDashboardData({ ...param, company_id: company.id });
      } else {
        return getDashboardData({ ...param, company_id: company.id, user_id: id });
      }
    },
    [user_type]
  );
  const callApiForDevices = useCallback(
    (param) => {
      if (user_type === SUPER_ADMIN) {
        return getActiveDevices({ ...param });
      } else if (user_type === CUSTOMER_ADMIN) {
        return getActiveDevices({ ...param, company_id: company.id });
      } else {
        return getActiveDevices({ ...param, company_id: company.id, user_id: id });
      }
    },
    [user_type]
  );

  const [queryObj, updateQueryObject] = useState({
    data: ['day_summary', 'currently_active', 'monthly_kva', 'monthly_kwh', 'total_device', 'quarter_summary', 'top_site,current_activity'],
    quarter_date: 2023,
    quarter: currentQuadrant(),
  });
  // useEffect(() => {
  //   gMap('map-box');
  // }, []);

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme === 'dark') document.documentElement.classList.add('dark');
    callApiForDevices({ page: 0, per_page: 100 });
  }, []);

  useEffect(() => {
    callApiForDashboard(queryObj);
    const event = setInterval(() => {
      callApiForDashboard(queryObj);
      callApiForDevices({ page: 0, per_page: 100 });
    }, refreshTime);

    return () => clearInterval(event);
  }, [queryObj]);

  return (
    <Suspender isError={isError} isLoading={isLoading}>
      <div className='home'>
        <div className='mb-6'>
          <HomeTile label='Home' />
        </div>
        <div className='mb-6'>
          <Summaries
            data={{
              totalRunTime: list.total_run_time,
              runningDevices: list.running_device,
              monthlyKVA: { kva: list.kva_data, kwh: list.kwh_data },
            }}
            updateQueryObject={updateQueryObject}
          />
        </div>
        <div className='mb-6'>
          <QuarterSummary
            updateQueryObject={updateQueryObject}
            data={{ chartData: list.quarter_data, topSiteData: list.top_site, totalDevices: list.total_device }}
          />
        </div>

        <div className='mb-6'>
          <div className='mt-10 lg:mt-0 rounded-lg p-4 custom-shadow'>
            <CurrentActivity data={list.current_activity} />
          </div>
        </div>

        <div className='mb-6 p-4 '>
          <div className='grid grid-cols-1 lg:grid-cols-3 gap-4'>
            <div className='map-box col-span-2  '>
              <h3 className='mb-6  text-center'>Map View</h3>
              {/* <div id='map-box' className=' h-72 outline outline-4 rounded outline-[#d3d3d31c]'></div> */}
              <LMap data={devices?.filter((item) => item.latitude !== null || !item.longitude == null)} />
            </div>
            <div className='pt-10'>
              <StyleBox style={{ width: '140px', height: '40px', marginBottom: '10px' }}>
                <p className='text-base'>
                  Active: <span className=' text-[#28A745] '>{actives.length}</span>
                </p>
              </StyleBox>
              <StyleBox style={{ width: '140px', height: '40px', marginBottom: '10px' }}>
                <p className='text-base'>
                  Inactive: <span className=' text-[#DD3B4B] '>{devices.length - actives.length}</span>
                </p>
              </StyleBox>

              <StyleBox style={{ width: '100%', height: '150px', marginBottom: '10px' }}></StyleBox>
            </div>
          </div>
        </div>
      </div>
    </Suspender>
  );
}
