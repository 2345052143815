import React, { useCallback, useState } from 'react';
import { ReactComponent as SendIcon } from '../assets/sendIcon.svg';

export default function TextBox({ onSend = () => {} }) {
  const [message, setMessage] = useState('');

  const handleTextAreaHeight = (element) => {
    element.target.style.height = '1px';
    element.target.style.height = 19 + element.target.scrollHeight + 'px';
  };

  const onSendMessage = useCallback(() => {
    if (message) {
      onSend(message);
    }
    setMessage('');
  }, [message, onSend]);
  return (
    <div className='flex bg-primary-100 text-primary-700 items-center border border-primary-300 rounded-sm '>
      <textarea
        onChange={(e) => setMessage(e.target.value)}
        onKeyUp={handleTextAreaHeight}
        value={message}
        style={{ background: 'inherit' }}
        className='w-full px-2 leading-5 overflow-hidden resize-none h-10 bg-inherit flex-1 focus:outline-none'
      />
      <div onClick={onSendMessage} className='h-[40px]  border-r-0 p-2 ml-auto border border-primary-300 bg-primary-200'>
        <SendIcon className=' w-6 transform -rotate-45 ' />
      </div>
    </div>
  );
}
