import React, { useEffect, useState } from 'react';
import './CreateUserForm.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CUSTOMER_ADMIN, getCompanies, getUserTypes, postFormData, SUPER_ADMIN } from '../../../../apis/apis';
import Button from '../../../basic/button/Button';
import Input from '../../../basic/inputs/Input/Input';
import SelectFromOption from '../../../basic/SelectedInput/SelectedInput';
import { makeSubmittableData } from '../makeSubmittableData';
import { formRest, validation } from '../validator';

export default function CreateUserForm() {
  const companyList = useSelector((state) => state.companies);
  const userTypes = useSelector((state) => state.userTypes);
  const [selCompany, setSelCompany] = useState({});
  const [selUserType, setSelUserType] = useState({});
  const { user = {} } = useSelector((state) => state.auth);
  const userType = user.data?.user_type || 3;
  const initFormData = {
    first_name: { value: '', required: true },
    last_name: { value: '' },
    email: { value: '', required: true },
    password: { value: '', required: true },
    company_id: { value: '', required: true },
    user_type: { value: '', required: true },
    description: { value: '' },
  };
  const [formData, updateFormData] = useState(initFormData);

  const resetter = () => {
    updateFormData(initFormData);
    if (userType === SUPER_ADMIN) {
      setSelCompany({});
    }
    setSelUserType({});
  };

  const navigate = useNavigate();
  const handleChange = (fieldName, data) => {
    updateFormData({ ...formData, [fieldName]: data });
  };

  const handleSubmit = async () => {
    let data = makeSubmittableData({
      ...formData,
    });

    if (data.status) {
      await postFormData(data.data, 'admin/user/signup', resetter);
    } else {
      updateFormData({ ...data.data });
    }
  };
  //for dropdown to catch changed value
  useEffect(() => {
    let copy = { ...formData };
    copy['user_type'].value = selUserType.id;
    copy['user_type'].error = false;
    copy['company_id'].value = selCompany.id;
    copy['company_id'].error = false;
    updateFormData({ ...copy });
  }, [selCompany, selUserType]);

  useEffect(() => {
    getCompanies();
    getUserTypes();
    if (userType === CUSTOMER_ADMIN) {
      setSelCompany(user.data.company);
    }
  }, []);

  return (
    <div className='create-user-form w-full h-full'>
      <div className='mb-5'>
        <Input
          name='first_name'
          value={formData.first_name?.value || ''}
          onChange={handleChange}
          validation={validation.name}
          placeholder='Type Your First Name'
          className='custom-input'
          type='text'
          fieldError={formData.first_name?.error || false}
        />
      </div>
      <div className='mb-5'>
        <Input
          name='last_name'
          value={formData.last_name?.value || ''}
          onChange={handleChange}
          placeholder='Type Your Last Name'
          className='custom-input'
          type='text'
        />
      </div>
      <div className='mb-5'>
        <Input
          name='email'
          value={formData.email?.value || ''}
          onChange={handleChange}
          validation={validation.email}
          placeholder='Type Your Email'
          className='custom-input'
          type='email'
          fieldError={formData.email?.error || false}
        />
      </div>
      <div className='mb-5'>
        <Input
          name='password'
          value={formData.password?.value || ''}
          onChange={handleChange}
          validation={validation.password}
          placeholder='Type Your Password'
          className='custom-input'
          type='password'
          fieldError={formData.password?.error || false}
        />
      </div>
      <div className='mb-5'>
        <SelectFromOption
          onClick={(op) => setSelCompany(op)}
          value={selCompany}
          options={companyList.list || []}
          headerClass='header-class'
          placeholder='Select Company'
          fieldError={formData.company_id?.error || false}
          disableOption={userType === CUSTOMER_ADMIN || false}
        />
      </div>

      <div className='mb-5'>
        <SelectFromOption
          onClick={(op) => setSelUserType(op)}
          value={selUserType}
          displayKey='type_name'
          options={userTypes.list || []}
          headerClass='header-class text-[13px]'
          placeholder='Select User Type'
          fieldError={formData.user_type?.error || false}
        />
      </div>
      <div className='mb-5'>
        <Input
          name='description'
          value={formData.description?.value || ''}
          onChange={handleChange}
          placeholder='Description'
          className='custom-input'
          type='text'
        />
      </div>

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass h-10 btn-normal' title='Save' />
      </div>
    </div>
  );
}
