import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { userFormUpdate } from '../../../apis/apis';
import Button from '../../../components/basic/button/Button';
import Input from '../../../components/basic/inputs/Input/Input';
import { makeSubmittableData } from '../../../components/common/forms/makeSubmittableData';
import { validation } from '../../../components/common/forms/validator';

export default function UserProfileUpdate() {
  const { user } = useSelector((state) => state.auth);

  const [formData, updateFormData] = useState({
    first_name: { value: user.data.first_name || '', required: true },
    last_name: { value: user.data.last_name || '' },
    description: { value: user.data.description || '' },
  });

  //   const navigate = useNavigate();

  const handleChange = (fieldName, data) => {
    updateFormData({ ...formData, [fieldName]: data });
  };

  const handleSubmit = async () => {
    let data = makeSubmittableData({
      ...formData,
    });
    if (data.status) {
      const response = await userFormUpdate(data.data);
      if (response.data.status === true) alert(`${response.data.message}`);
      else alert(`${response.data.data.error}`);
    } else {
      updateFormData({ ...data.data });
    }
  };

  //for dropdown to catch changed value

  return (
    <div className='user-update max-w-xl mx-auto h-full'>
      <div className='mb-5'>
        <Input
          name='first_name'
          value={formData.first_name.value}
          onChange={handleChange}
          validation={validation.name}
          placeholder='Type Your First Name'
          className='custom-input'
          type='text'
          fieldError={formData.first_name?.error || false}
        />
      </div>
      <div className='mb-5'>
        <Input
          name='last_name'
          value={formData.last_name.value}
          onChange={handleChange}
          placeholder='Type Your Last Name'
          className='custom-input'
          type='text'
        />
      </div>

      <div className='mb-5'>
        <Input
          name='description'
          value={formData.description.value}
          onChange={handleChange}
          placeholder='Description'
          className='custom-input'
          type='text'
        />
      </div>

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass btn-normal h-10' title='Update' />
      </div>
    </div>
  );
}
