import { createSlice } from '@reduxjs/toolkit';

const companiesSlice = createSlice({
  name: 'companies',
  initialState: { isLoading: false, list: [], isError: false },
  reducers: {
    companiesInit: (state) => {
      return { ...state, isLoading: true };
    },
    companiesSuccess: (state, action) => {
      return { ...state, list: action.payload.data, isLoading: false };
    },
    companiesFail: (state, action) => {
      return { ...state, list: [], isLoading: false, isError: action.payload.data };
    },
    companiesDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    companiesDelSuccess: (state, action) => {
      return {
        ...state,
        list: state.list.filter((item) => !action.payload.data.includes(item.id)),
        isLoading: false,
      };
    },
    companiesDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const {
  companiesInit,
  companiesSuccess,
  companiesFail,
  companiesDelInit,
  companiesDelSuccess,
  companiesDelFail,
} = companiesSlice.actions;
export default companiesSlice.reducer;
