import { createSlice } from '@reduxjs/toolkit';

const userTypesSlice = createSlice({
  name: 'userTypes',
  initialState: { isLoading: false, list: [], isError: false },
  reducers: {
    userTypesInit: (state) => {
      return { ...state, isLoading: true };
    },
    userTypesSuccess: (state, action) => {
      return { ...state, list: action.payload.data, isLoading: false };
    },
    userTypesFail: (state, action) => {
      return { ...state, list: [], isLoading: false, isError: action.payload.data };
    },
  },
});

export const { userTypesInit, userTypesSuccess, userTypesFail } = userTypesSlice.actions;
export default userTypesSlice.reducer;
