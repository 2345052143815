import { createSlice } from '@reduxjs/toolkit';

const devicesSlice = createSlice({
  name: 'devices',
  initialState: { isLoading: false, list: [], isError: false },
  reducers: {
    devicesInit: (state) => {
      return { ...state, isLoading: true };
    },
    devicesSuccess: (state, action) => {
      return { ...state, list: action.payload.data, isLoading: false };
    },
    devicesFail: (state, action) => {
      return { ...state, list: [], isLoading: false, isError: action.payload.data };
    },

    devicesDelInit: (state) => {
      return { ...state, isLoading: true };
    },

    devicesDelSuccess: (state, action) => {
      return {
        ...state,
        list: { ...state.list, data: state.list.data.filter((item) => !action.payload.data.includes(item.device_id)) },
        isLoading: false,
      };
    },

    devicesDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { devicesInit, devicesSuccess, devicesFail, devicesDelInit, devicesDelSuccess, devicesDelFail } = devicesSlice.actions;
export default devicesSlice.reducer;
