import React, { useState } from 'react';
import Button from '../../../basic/button/Button';
import CheckBox from '../../../basic/check-box/CheckBox';
import Input from '../../../basic/inputs/Input/Input';
import { validation } from '../validator';
import './LoginForm.css';
import { useNavigate } from 'react-router-dom';
import { makeSubmittableData } from '../makeSubmittableData';
import { loginByToken } from '../../../../apis/apis';

export default function LoginForm() {
  const [formData, updateFormData] = useState({});
  const navigate = useNavigate();

  const handleChange = (fieldName, data) => {
    updateFormData({ ...formData, [fieldName]: data });
  };

  const handleSubmit = () => {
    let data = makeSubmittableData(formData);
    if (data.status) {
      loginByToken(data.data, () => navigate('/'));
    }
  };

  return (
    <div className='login-form w-full h-full'>
      <div className='mb-5'>
        <Input
          name='email'
          onChange={handleChange}
          validation={validation.email}
          placeholder='Type Your Email'
          className='custom-input '
          type='email'
          fieldError={formData.email?.error || false}
        />
      </div>
      <div className='mb-5'>
        <Input
          name='password'
          onChange={handleChange}
          validation={validation.password}
          placeholder='Type Your Password'
          className='custom-input'
          type='password'
          fieldError={formData.password?.error || false}
        />
      </div>
      <div className='mb-10'>
        <CheckBox label='Remember me' />
      </div>

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass btn-normal h-10' title='Login' />
      </div>

      <div className='mb-4 text-center'>
        <h6 onClick={() => navigate('/user/auth/password/reset')} className='text-[#437AC7] hover:opacity-80 cursor-pointer text-sm'>
          Forget Password?
        </h6>
      </div>
    </div>
  );
}
