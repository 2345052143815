import React, { useState } from 'react';
import { userPasswordChange } from '../../../apis/apis';
import Button from '../../../components/basic/button/Button';
import Input from '../../../components/basic/inputs/Input/Input';
import { makeSubmittableData } from '../../../components/common/forms/makeSubmittableData';
import { validation } from '../../../components/common/forms/validator';

export default function PasswordChange() {
  const [formData, updateFormData] = useState({
    current_password: { value: '', required: true },
    new_password: { value: '', required: true },
  });

  //   const navigate = useNavigate();

  const handleChange = (fieldName, data) => {
    updateFormData({ ...formData, [fieldName]: data });
  };

  const handleSubmit = async () => {
    let data = makeSubmittableData({
      ...formData,
    });
    if (data.status) {
      const response = await userPasswordChange(data.data);
      if (response.data.status === true) alert(`Password Change Successfully`);
      else alert(`${response.data.data.error}`);
    } else {
      updateFormData({ ...data.data });
    }
  };
  //for dropdown to catch changed value

  return (
    <div className='user-update max-w-xl mx-auto h-full'>
      <div className='mb-5'>
        <Input
          name='current_password'
          onChange={handleChange}
          validation={validation.password}
          placeholder='Type Your Old Password'
          className='custom-input'
          type='password'
          fieldError={formData.current_password?.error || false}
        />
      </div>
      <div className='mb-5'>
        <Input
          name='new_password'
          onChange={handleChange}
          validation={validation.password}
          placeholder='Type Your New Password'
          className='custom-input'
          type='password'
          fieldError={formData.new_password?.error || false}
        />
      </div>

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass h-10 btn-normal' title='Update Password' />
      </div>
    </div>
  );
}
