import React from 'react';
import { useNavigate } from 'react-router-dom';
import MiniNav from '../../../../components/common/mini-nav';
import { getFormattedServiceHours, parseDateTime } from '../../../../utils/utils';
import './CurrentActivity.css';

export default function CurrentActivity(props) {
  const navigate = useNavigate();
  return (
    <div className='current-activity whitespace-nowrap overflow-x-auto'>
      <MiniNav title='Current-Activity' />

      <div className=''>
        <table className='w-full text-center text-primary-dark overflow-x-auto'>
          <thead>
            <tr>
              <th>PG Number</th>
              <th>Total Run Time</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Total Power (KWh)</th>
              <th>Site Code</th>
            </tr>
          </thead>
          <tbody>
            {props.data?.map((item, i) => (
              <tr
                onClick={() => navigate(`device/active-devices/logs/${item.pg_number}/${item.device_id}`)}
                className='border-2 h-12 border-x-0 cursor-pointer'
                key={i}
              >
                <td className='text-left '>
                  <p className='flex items-center'>
                    <span className={`w-4 h-4 mr-2 rounded-full inline-block ${item.running ? 'bg-secondary-600' : 'bg-primary-400'}`}></span>
                    {item.pg_number || ''}
                  </p>
                </td>
                <td>{getFormattedServiceHours(item.run_hour)}</td>
                <td>
                  {parseDateTime(item.start_time).date} {parseDateTime(item.start_time).time}
                </td>
                <td>{parseDateTime(item.end_time).time}</td>
                <td>{item.run_kwh}</td>
                <td>{item.code}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
