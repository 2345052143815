import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getUserType } from '../utils/utils';

export default function ControlElement() {
  const { user = {} } = useSelector((state) => state.auth);
  const { data = {} } = user;

  return <Navigate to={`${data.user_type ? `${getUserType(data.user_type)}` : '/login'}`} replace />;
}
