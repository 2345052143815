import { useState } from 'react';
import './RenderTab.css';
import { ReactComponent as DownArrow } from '../../../assets/icons/downArrow.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getSidebarData } from './tabData';

export default function RenderTab({ product }) {
  const { user = {} } = useSelector((state) => state.auth);
  const tabList = getSidebarData(user.data?.user_type);
  const [activeTab, setActiveTab] = useState(tabList[0]);

  return (
    <div className='render-tab'>
      <ul className='labels-wrapper'>
        {tabList?.map((tb) => (
          <li
            onClick={() => setActiveTab(tb)}
            className={'label capitalize ' + (tb.label === activeTab.label && 'active-class')}
            key={tb.id}
          >
            {tb.icon ? <div className='w-5'>{tb.icon} </div> : tb.label}
          </li>
        ))}
      </ul>
      <div className='tab-body'>
        <ReturnTabList list={activeTab.subItem} />
      </div>
    </div>
  );
}

export function ReturnTabList({ list = [] }) {
  const navigate = useNavigate();
  const [expandList, updateExpandList] = useState([]);
  const [activeLink, setActiveLink] = useState(null);

  const handleExpandList = (id) => {
    if (expandList.includes(id)) updateExpandList((state) => state.filter((it) => it !== id));
    else updateExpandList((state) => [...state, id]);
  };

  return (
    <div className='sub-tab-body'>
      {list?.map((li) => (
        <div key={li.id}>
          <ul
            onClick={() => handleExpandList(li.id)}
            className={`my-1 flex bg-primary-200 text-primary-600 dark:bg-primary-600   px-3 py-2 dark:text-primary-100 items-center cursor-pointer text-sm ${
              expandList.includes(li.id) && 'expand  '
            }`}
          >
            <li className='mr-3'>
              {/* <img src={li.icon} alt='' width='16px' /> */}
              <div className='w-3'>{li.icon}</div>
            </li>
            <li className=''>{li.label}</li>

            <li className={`ml-auto transform duration-200 ${expandList.includes(li.id) && ' rotate-180 '}`}>
              <div className='w-3 opacity-40'>
                <DownArrow />
              </div>
            </li>
          </ul>

          {li.subItem && (
            <div className='expand-body'>
              {li.subItem?.map((liSub) => (
                <ul
                  onClick={() => {
                    setActiveLink(liSub.link);
                    navigate(`${liSub.link}`, { replace: true });
                  }}
                  key={liSub.id}
                  className={`my-3 items-center cursor-pointer  ${activeLink === liSub.link && 'opacity-70'} `}
                >
                  <li className='mr-3'>
                    <img src={liSub.icon} alt='' width='16px' />
                  </li>
                  <li className='text-sm'>{liSub.label}</li>
                </ul>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
