import React from 'react';
import CreateTowerSiteForm from '../../../components/common/forms/create-tower-site-form/CreateTowerSiteForm';

export default function CreateTowerSite() {
  return (
    <div className='create-tower-site'>
      <div className='create-company max-w-xl mx-auto'>
        <CreateTowerSiteForm />
      </div>
    </div>
  );
}
