import React, { useState } from 'react';
import { postFormData } from '../../../../apis/apis';
import TextBox from '../../../../pages/chat-board/components/TextBox';
import { makeFormObjectFromArray } from '../../../../utils/utils';
import Button from '../../../basic/button';
import Input from '../../../basic/inputs/Input/Input';
import Textarea from '../../../basic/text-area/Textarea';
import { makeSubmittableData } from '../makeSubmittableData';
import { validation } from '../validator';

export default function ContactUsForm() {
  const initFormData = makeFormObjectFromArray([
    { fieldName: 'name', value: '', required: true },
    { fieldName: 'email', value: '', required: true },
    { fieldName: 'message', value: '', required: true },
  ]);
  const [formData, updateFormData] = useState(initFormData);

  const handleChange = (fieldName, data) => {
    updateFormData({ ...formData, [fieldName]: data });
  };

  const resetter = () => {
    updateFormData(initFormData);
  };

  const handleSubmit = async () => {
    let data = makeSubmittableData({
      ...formData,
    });
    if (data.status) {
      //   await postFormData(data.data, 'zone/create', resetter);
    } else {
      updateFormData({ ...data.data });
    }
  };

  //for dropdown to catch changed value

  return (
    <div className='contact-us-form w-full h-full'>
      <div className='mb-5'>
        <Input
          name='name'
          value={formData.name?.value}
          onChange={handleChange}
          validation={validation.required}
          placeholder='Your Name'
          className='custom-input'
          type='text'
          fieldError={formData.name?.error || false}
        />
      </div>
      <div className='mb-5'>
        <Input
          name='email'
          value={formData.email?.value}
          onChange={handleChange}
          validation={validation.required}
          placeholder='Your email'
          className='custom-input'
          type='text'
          fieldError={formData.email?.error || false}
        />
      </div>
      <div className='mb-5'>
        <Textarea
          name='message'
          value={formData.message?.value}
          onChange={handleChange}
          validation={validation.message}
          placeholder='Your Message'
          className='custom-text-input'
          type='text'
          fieldError={formData.message?.error || false}
        />
      </div>

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass h-10 btn-normal' title='Send' />
      </div>
    </div>
  );
}
