import React from 'react';
import CreateCompanyForm from '../../../components/common/forms/create-company-form/CreateCompanyForm';

export default function CreateCompany() {
  return (
    <div className='create-company max-w-xl mx-auto'>
      <CreateCompanyForm />
    </div>
  );
}
