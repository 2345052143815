import React, { useState } from 'react';
import './dropDown.css';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// export function WebSubItemDisplay({ item }) {
//   const { title = '', subTitle = '', rightIcon = null, leftIcon = null, underLine = null } = item;
//   return (
//     <>
//       <ul className='mb-4 flex items-center group cursor-pointer'>
//         {leftIcon && <ul className={`icon-style group-hover:opacity-100`}>{leftIcon()}</ul>}
//         <ul className='flex-1 my-2  mr-[50px]'>
//           <li className='mb-1 text-sm cursor-pointer font-medium w-full  text-primary-dark'>{title}</li>
//           <li className='text-xs cursor-pointer max-w-[252px]  w-full font-normal text-primary'>{subTitle}</li>
//         </ul>
//         {rightIcon && <ul className='opacity-0 group-hover:opacity-100'>{rightIcon()}</ul>}
//       </ul>
//       {underLine && underLine}
//     </>
//   );
// }

// export function DropDownWeb({ item }) {
//   const { title = '', subList = [] } = item;
//   return (
//     <>
//       <ul>
//         <li>{title}</li>
//       </ul>
//       <div className='body flex '>
//         <div className='content'>
//           <div className='group-wrapper'>
//             {subList?.map((subItem) => (
//               <WebSubItemDisplay key={subItem.id} item={subItem} />
//             ))}
//           </div>
//           {item.subBody ? <div className='subContent'>{item.subBody}</div> : null}
//         </div>
//       </div>
//     </>
//   );
// }

export function DropDownMobile({ item, children }) {
  const { id = '', title = '', leftIcon = () => {}, rightIcon = () => {}, subList = [] } = item;
  const [expandedIds, updateIds] = useState([]);
  const parentDiv = useRef();
  const headerRef = useRef();
  const location = useLocation();

  const removeId = () => {
    updateIds(() => expandedIds.filter((it) => it !== id));
  };

  const handleClick = () => {
    if (expandedIds.includes(id)) {
      removeId();
    } else {
      updateIds([...expandedIds, id]);
    }
  };

  const eventFun = (e) => {
    if (parentDiv.current && headerRef.current) {
      if (!headerRef.current.contains(e.target) && !parentDiv.current.contains(e.target)) {
        updateIds(() => expandedIds.filter((it) => it !== id));
      }
    }
  };

  useEffect(() => {
    window.addEventListener('click', eventFun);
    return () => window.removeEventListener('click', eventFun);
  }, []);

  useEffect(() => {
    removeId();
  }, [location]);

  return (
    <>
      <ul
        ref={headerRef}
        onClick={handleClick}
        className={`flex items-center  w-full cursor-pointer font-medium text-primary text-lg ${
          expandedIds.includes(id) && 'expanded'
        }`}
      >
        {leftIcon && <ul className={`icon-style group-hover:opacity-100`}>{leftIcon()}</ul>}
        <li className='flex-1 text-ellipsis whitespace-nowrap overflow-hidden w-40'>{title}</li>
        {rightIcon()}
      </ul>
      <div ref={parentDiv} className={`body ${expandedIds.includes(id) && 'expanded'}`}>
        <ul>
          {children
            ? children
            : subList?.map((item) => (
                <li key={item.id} className='text-base cursor-pointer w-full font-normal text-primary'>
                  {item.title}
                </li>
              ))}
        </ul>
      </div>
    </>
  );
}
export default function DropDown(props) {
  return (
    <>
      <div className='drop-down-mobile '>
        <DropDownMobile {...props} />
      </div>
      {/* <div className='drop-down-web  hidden lg:block'>
        <DropDownWeb {...props} />
      </div> */}
    </>
  );
}

DropDown.propTypes = {
  item: PropTypes.object.isRequired,
};
