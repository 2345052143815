import { getToday } from '../../../../utils/utils';

export const quarterData = [
  {
    id: 1,
    name: 'Jan',
    uv: 4000,
    fill: '#70BDD1',
  },
  {
    id: 2,
    name: 'Feb',
    uv: 3500,
    fill: '#b0c978',
  },
  {
    id: 3,
    name: 'Mar',
    uv: 2200,
    fill: '#f9ab6b',
  },
];

export const options = [
  { id: 1, title: 'Jan-Mar', value: 'data' },
  { id: 2, title: 'Apr-Jul', value: 'data' },
  { id: 3, title: 'Aug-Sep', value: 'data' },
  { id: 4, title: 'Oct-Dec', value: 'data' },
];
export const optionsDate = [
  { id: 1, title: 'Jan-Mar', value: 1 },
  { id: 2, title: 'Apr-Jul', value: 2 },
  { id: 3, title: 'Aug-Sep', value: 3 },
  { id: 4, title: 'Oct-Dec', value: 4 },
];
export const optionsYear = [
  { id: 11, title: '2030 ', value: 2030 },
  { id: 12, title: '2029 ', value: 2029 },
  { id: 13, title: '2028', value: 2028 },
  { id: 14, title: '2027', value: 2027 },
  { id: 15, title: '2026', value: 2026 },
  { id: 16, title: '2025', value: 2025 },
  { id: 17, title: '2024', value: 2024 },
  { id: 18, title: '2023', value: 2023 },
  { id: 19, title: '2022', value: 2022 },
  { id: 20, title: '2021', value: 2021 },
  { id: 21, title: ' 2020', value: 2020 },
];

export const optionsForTopSite = [
  { id: 2, title: 'Last 1 day', value: 24 * 60 * 60 },
  { id: 3, title: 'Last 15 day', value: 15 * 24 * 60 * 60 },
  { id: 4, title: 'Last 30 day', value: 30 * 24 * 60 * 60 },
  { id: 5, title: 'Last 3 month', value: 90 * 24 * 60 * 60 },
  { id: 6, title: 'Last 6 month', value: 180 * 24 * 60 * 60 },
  { id: 9, title: 'Last 1 year', value: 365 * 24 * 60 * 60 },
  { id: 10, title: 'All', value: 100 * 365 * 24 * 60 * 60 },
];
