import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedServiceHours, parseDateTime } from '../../../../utils/utils';
import './ActiveDeviceCard.css';

export default function ActiveDeviceCard({ item, onClick = () => {} }) {
  return (
    <div
      onClick={() => onClick(item)}
      className='active-device-card w-full aspect-square p-6 cursor-pointer rounded-lg  custom-shadow bg-primary-50 dark:bg-primary-700 opacity-70 '
    >
      <div className='grid grid-flow-row grid-cols-8 gap-3 text-primary-700 dark:text-primary-100'>
        <div className='col-span-8 p-3 relative bg-primary-200 dark:bg-primary-600'>
          <h5>
            <span>PG Number:</span> {item.pg_number}
          </h5>
          <h5>
            <span>Device ID:</span> {item.device_id}
          </h5>
          {item.running == 1 ? (
            <p className='w-5 h-5 rounded-full absolute right-3 top-3 bg-secondary-500'></p>
          ) : (
            <p className='w-5 h-5 rounded-full absolute right-3 top-3 bg-primary-400'></p>
          )}
        </div>
        <div className='body col-span-3'>
          <h5>
            <span>Code:</span> {item.code}
          </h5>
          <h5>
            <span>Lat:</span> {item.latitude}
          </h5>
          <h5>
            <span>Lan:</span> {item.longitude}
          </h5>
        </div>
        <div className='body  col-span-5'>
          <h5>
            <span>Run Hour:</span> {getFormattedServiceHours(item.duration)}
          </h5>
          <h5>
            <span>Start Time:</span> {parseDateTime(item.start_time).time}
          </h5>
          <h5>
            <span>End Time:</span> {parseDateTime(item.end_time).time}{' '}
          </h5>
          <h5>
            <span>Temperature:</span> {item.temperature} <span>&#176;</span>C
          </h5>
          <h5>
            <span>Voltage:</span> {item.voltage} V
          </h5>
          <h5>
            <span>Current:</span> {item.current} A
          </h5>
          <h5>
            <span>Fuel Consumed:</span> {item.fuel_consumed} Ltr
          </h5>
          <h5>
            <span>Current Fuel:</span> {item.last_fuel_meter} Ltr
          </h5>
        </div>
      </div>
    </div>
  );
}

ActiveDeviceCard.propTypes = {
  item: PropTypes.object,
};
