import React, { useState } from 'react';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from '../components/common/navbar/Navbar';
import Sidebar from '../components/common/side-bar/Sidebar';
import './layout.css';

export default function Layout() {
  const [expand, setExpand] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (window.screen.width < 1024) {
      setExpand(false);
    }
  }, [location]);

  return (
    <div className='layout lg:container mx-auto '>
      <Navbar onClick={() => setExpand(!expand)} />
      <div className='main-section'>
        <div className={`left-part  ${expand ? 'left-0 visible' : 'left-[-250px] collapse lg:visible'}`}>
          <div className='sticky top-0'>
            <Sidebar />
          </div>
        </div>
        <div className=' right-part '>{<Outlet />}</div>
      </div>
      {/* footer */}
      {/* <div>This for footer</div> */}
    </div>
  );
}
