import React from 'react';
import './FuelLevel.css';

//0 t0 260
export default function FuelLevel({ percentage = 200, strokeWidth = '16', barColor = 'red', bgColor = 'gray' }) {
  return (
    <div className='fuel-level'>
      <svg xmlns='http://www.w3.org/2000/svg' height='100' width='200' viewBox='40 40 150 150' data-value='40'>
        <defs>
          <linearGradient id='gradient' x1='0%' y1='0%' x2='100%' y2='0%'>
            <stop offset='0%' stopColor='#00d4ff' />
            <stop offset='100%' stopColor='#2200ff' />
          </linearGradient>
        </defs>
        <path className='bg' strokeWidth={strokeWidth} stroke={bgColor} d='M41 149.5a77 77 0 1 1 160 0' fill='none'></path>
        <path
          className='bar'
          stroke={`url('#gradient')`}
          d='M41 149.5a77 77 0 1 1 160 0'
          fill='none'
          strokeWidth={strokeWidth}
          strokeDasharray='80 3 86 3 80'
          strokeDashoffset={360 - (100 * 360) / 100}
        ></path>
      </svg>
    </div>
  );
}
