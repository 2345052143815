import { createSlice } from '@reduxjs/toolkit';

const deviceLogsSlice = createSlice({
  name: 'deviceLogs',
  initialState: { isLoading: false, list: [], isError: false },
  reducers: {
    deviceLogsInit: (state) => {
      return { ...state, isLoading: true };
    },
    deviceLogsSuccess: (state, action) => {
      return { ...state, list: action.payload.data, isLoading: false };
    },
    deviceLogsFail: (state, action) => {
      return { ...state, list: [], isLoading: false, isError: action.payload.data };
    },

    deviceLogsDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    deviceLogsDelSuccess: (state, action) => {
      return {
        ...state,
        list: state.list.filter((item) => !action.payload.data.includes(item.pg_number)),
        isLoading: false,
      };
    },
    deviceLogsDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const {
  deviceLogsInit,
  deviceLogsSuccess,
  deviceLogsFail,
  deviceLogsDelInit,
  deviceLogsDelSuccess,
  deviceLogsDelFail,
} = deviceLogsSlice.actions;
export default deviceLogsSlice.reducer;
