import React from 'react';
import CreateUserForm from '../../../components/common/forms/create-user-form/CreateUserForm';

export default function CreateUser() {
  return (
    <div className='register max-w-xl mx-auto'>
      <CreateUserForm />
    </div>
  );
}
