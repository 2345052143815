import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { getUser } from '../apis/apis';
import Chart from '../components/common/Chart';
import Layout from '../layout';
import Home from '../pages/home';
import Login from '../pages/login/Login';

import RootElement from '../pages/root-element/RootElement';
import UserList from '../pages/user/user-list';
import UserProfileUpdate from '../pages/user/user-update-info';

import PasswordChange from '../pages/user/password-change';
import PasswordResetIndex from '../pages/user/password-reset';
import PasswordResetForm from '../pages/user/password-reset/PasswordResetForm';
import CompanyList from '../pages/company/company-list';
import ErrorBoundary from '../pages/error-boundary';
import ControlElement from './ControlElement';
import TowerSiteList from '../pages/tower-site/tower-site-list';
import CreateTowerSite from '../pages/tower-site/create-tower-site';
import TowerSiteFileUpload from '../pages/tower-site/tower-site-file-upload';
import CreateCompany from '../pages/company/create-company';
import CreateUser from '../pages/user/create-user';
import AddDevice from '../pages/device/add-device';
import DeviceFileUpload from '../pages/device/upload-devices';
import DeviceList from '../pages/device/device-list';
import AddPG from '../pages/pgm/add-pg';
import PGList from '../pages/pgm/pg-list';
import PGFileUpload from '../pages/pgm/upload-pg';
import PGAttach from '../pages/pgm/attach-pg';
import ActiveDevices from '../pages/device/active-devices';
import ActiveDeviceLogs from '../pages/device/active-device-logs';
import UserPGList from '../pages/pgm/user-pg-list';
import ReportGenerate from '../pages/dashboard/report-generate';
import ChatBoard from '../pages/chat-board';
import AddZone from '../pages/zone/add-zone';
import AddFaultType from '../pages/fault-type/fault-type-add/index';
import ZoneList from '../pages/zone/zone-list';
import FaultTypeList from '../pages/fault-type/fault-types';
import AddFaultTypeForm from '../components/common/forms/add-fault-fault-type-form/AddFaultTypeForm';
import Contact from '../pages/contact';
import TicketList from '../pages/ticket/tickets';
import FilterOnMap from '../pages/filter-on-map';

export function router() {
  const routes = createBrowserRouter(
    createRoutesFromElements(
      <Route path='/' element={<RootElement />}>
        <Route index element={<ControlElement />} loader={getUser} />
        <Route path='user/auth/password/reset' element={<PasswordResetIndex />} />
        <Route path='user/auth/reset-password/:token/:email' element={<PasswordResetForm />} />
        <Route path='login' element={<Login />} />
        <Route path='super-admin/*' element={<Layout />} loader={getUser}>
          <Route index element={<Home />} />
          <Route path='chart' element={<Chart />} />
          <Route path='pg/add' element={<AddPG />} />
          <Route path='pg/list' element={<PGList />} />
          <Route path='pg/list/:userId' element={<UserPGList />} />
          <Route path='pg/file-upload' element={<PGFileUpload />} />
          <Route path='pg/attach' element={<PGAttach />} />
          <Route path='device/add' element={<AddDevice />} />
          <Route path='device/list' element={<DeviceList />} />
          <Route path='device/file-upload' element={<DeviceFileUpload />} />
          <Route path='tower-site/create' element={<CreateTowerSite />} />
          <Route path='tower-site/list' element={<TowerSiteList />} />
          <Route path='tower-site/file-upload' element={<TowerSiteFileUpload />} />
          <Route path='company/create' element={<CreateCompany />} />
          <Route path='company/list' element={<CompanyList />} />
          <Route path='user/create' element={<CreateUser />} />
          <Route path='user/list' element={<UserList />} />
          <Route path='user/profile/update' element={<UserProfileUpdate />} />
          <Route path='user/profile/password/change' element={<PasswordChange />} />

          <Route path='device/active-devices' element={<ActiveDevices />} />
          <Route path='device/active-devices/logs/:pg_number/:device_id' element={<ActiveDeviceLogs />} />
          <Route path='dashboard/reports' element={<ReportGenerate />} />
          <Route path='chat/board' element={<ChatBoard />} />
          <Route path='map/filter' element={<FilterOnMap />} />

          <Route path='zone/add' element={<AddZone />} />
          <Route path='zone/list' element={<ZoneList />} />
          <Route path='fault-type/add' element={<AddFaultTypeForm />} />
          <Route path='fault-type/list' element={<FaultTypeList />} />
          <Route path='ticket/board' element={<TicketList />} />

          <Route path='contact-us' element={<Contact />} />

          <Route path='*' element={<ErrorBoundary />} />
        </Route>

        <Route path='customer-admin/*' element={<Layout />} loader={getUser}>
          <Route index element={<Home />} />
          <Route path='chart' element={<Chart />} />
          <Route path='pg/add' element={<AddPG />} />
          <Route path='pg/list' element={<PGList />} />
          <Route path='pg/list/:userId' element={<UserPGList />} />
          <Route path='pg/file-upload' element={<PGFileUpload />} />
          <Route path='pg/attach' element={<PGAttach />} />
          <Route path='device/list' element={<DeviceList />} />
          <Route path='tower-site/create' element={<CreateTowerSite />} />
          <Route path='tower-site/list' element={<TowerSiteList />} />
          <Route path='tower-site/file-upload' element={<TowerSiteFileUpload />} />
          <Route path='user/create' element={<CreateUser />} />
          <Route path='user/list' element={<UserList />} />
          <Route path='user/profile/update' element={<UserProfileUpdate />} />
          <Route path='user/profile/password/change' element={<PasswordChange />} />
          <Route path='*' element={<ErrorBoundary />} />

          <Route path='device/active-devices' element={<ActiveDevices />} />
          <Route path='device/active-devices/logs/:pg_number/:device_id' element={<ActiveDeviceLogs />} />
          <Route path='dashboard/reports' element={<ReportGenerate />} />

          <Route path='chat/board' element={<ChatBoard />} />

          <Route path='zone/add' element={<AddZone />} />
          <Route path='zone/list' element={<ZoneList />} />
          <Route path='fault-type/add' element={<AddFaultTypeForm />} />
          <Route path='fault-type/list' element={<FaultTypeList />} />
          <Route path='ticket/board' element={<TicketList />} />

          <Route path='contact-us' element={<Contact />} />
          <Route path='*' element={<ErrorBoundary />} />
        </Route>

        <Route path='customer-user/*' element={<Layout />} loader={getUser}>
          <Route index element={<Home />} />
          <Route path='chart' element={<Chart />} />
          <Route path='pg/list' element={<PGList />} />
          <Route path='pg/attach' element={<PGAttach />} />

          <Route path='user/profile/update' element={<UserProfileUpdate />} />
          <Route path='user/profile/password/change' element={<PasswordChange />} />

          <Route path='device/active-devices' element={<ActiveDevices />} />
          <Route path='device/active-devices/logs/:id' element={<ActiveDeviceLogs />} />
          <Route path='dashboard/reports' element={<ReportGenerate />} />

          <Route path='zone/add' element={<AddZone />} />
          <Route path='zone/list' element={<ZoneList />} />
          <Route path='fault-type/add' element={<AddFaultTypeForm />} />
          <Route path='fault-type/list' element={<FaultTypeList />} />
          <Route path='ticket/board' element={<TicketList />} />

          <Route path='contact-us' element={<Contact />} />

          <Route path='chat/board' element={<ChatBoard />} />
          <Route path='*' element={<ErrorBoundary />} />
        </Route>

        <Route path='*' element={<ErrorBoundary />} />
      </Route>
    )
  );
  return routes;
}
