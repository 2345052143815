import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { deleteCompanies, getCompanies } from '../../../apis/apis';
import Modal from '../../../components/basic/Modals/modal with create Portal/Modal';
import ModalDelConfirmation from '../../../components/common/modal-del-confirmation';
import NoData from '../../../components/common/no-data/NoData';
import Suspender from '../../../components/common/suspender/Suspender';
import './style.css';

export default function CompanyList() {
  const { isLoading, isError, list } = useSelector((state) => state.companies);
  const [queryObj, updateQueryObject] = useState({ page: 0, per_page: 5 });
  const [modalItem, setModalItem] = useState(null);

  const handleConfirm = () => {
    deleteCompanies(modalItem.id);
    setModalItem(null);
  };

  useEffect(() => {
    getCompanies(queryObj);
  }, [queryObj]);

  return (
    <Suspender isLoading={isLoading} isError={isError}>
      {modalItem && (
        <Modal onClose={() => setModalItem(null)}>
          <ModalDelConfirmation onCancel={() => setModalItem(null)} onConfirm={handleConfirm} />
        </Modal>
      )}
      <div className='tower-sites p-4'>
        <div className='table-container h-[530px] overflow-y-auto'>
          <table className='w-full text-center text-primary-dark '>
            <thead className='h-12'>
              <tr>
                <th>
                  <pre>User Id</pre>
                </th>
                <th>
                  <pre>Name</pre>
                </th>
                <th>
                  <pre>User Email</pre>
                </th>
                <th>
                  <pre>Phone</pre>
                </th>
                <th>
                  <pre>Country</pre>
                </th>
                <th>
                  <pre>Action</pre>
                </th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item) => (
                <tr key={item.id}>
                  <td className=''>{item.id || '-'}</td>
                  <td>{item?.name || '-'}</td>
                  <td>{item.email || '-'}</td>
                  <td>{item.phone || '-'}</td>
                  <td>{item.country.name || '-'}</td>
                  <td>
                    <div className='flex items-center justify-center gap-1 h-8'>
                      {/* <h4 className='w-12 rounded-md h-full cursor-pointer border border-[#28a745] flex items-center justify-center'>
                        Edit
                      </h4> */}
                      <h4
                        onClick={() => setModalItem(item)}
                        className='w-12 rounded-md h-full cursor-pointer border border-[#dc3545] flex items-center justify-center'
                      >
                        delete
                      </h4>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {list?.data === 0 && <NoData />}
        </div>

        {/* <div className='my-4'>
        <PaginatedItems
          itemsPerPage={queryObj.per_page}
          onClick={(parm) => updateQueryObject({ ...queryObj, page: parm })}
          totalItems={list?.length}
        />
      </div> */}
      </div>
    </Suspender>
  );
}
