import { useEffect, useRef, useState } from 'react';
import './progress.css';

export default function Progress({
  children,
  current = 100,
  bgColor = 'gray',
  barColor = 'green',
  height = '4px',
  width = '100%',
  barClass = '',
  border = '',
  brRadius = '',
}) {
  const barRef = useRef();
  const [parentWidth, setParentWidth] = useState();

  const parentStyle = {
    background: bgColor,
    width: width,
    height: height,
    border: border || null,
    borderRadius: brRadius || null,
  };
  const barStyle = {
    background: barColor || null,
  };
  useEffect(() => {
    if (barRef.current) {
      setParentWidth(barRef.current.parentNode.clientWidth);
      barRef.current.style.width = `${parentWidth * (current / 100)}px`;
      barRef.current.style.transition = 'all 1.5s ease-out .05s';
    }
  }, [parentWidth, current]);

  const listener = () => {
    setParentWidth(barRef.current.parentNode.clientWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  return (
    <>
      {children && children}
      <div className='progress' style={parentStyle}>
        <div ref={barRef} className={`bar ${barClass}`} style={barStyle}></div>
      </div>
    </>
  );
}
