import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
  name: 'users',
  initialState: { isLoading: false, list: [], isError: false },
  reducers: {
    usersInit: (state) => {
      return { ...state, isLoading: true };
    },
    usersSuccess: (state, action) => {
      return { ...state, list: action.payload.data, isLoading: false };
    },
    usersFail: (state, action) => {
      return { ...state, list: [], isLoading: false, isError: action.payload.data };
    },

    usersUpdateInit: (state) => {
      return { ...state, isLoading: true };
    },
    usersUpdateSuccess: (state, action) => {
      return {
        ...state,
        list: {
          ...state.list,
          data: state.list.data.map((item) =>
            item.id === action.payload.data.user_id ? { ...item, status: action.payload.data.status } : item
          ),
        },

        isLoading: false,
      };
    },
    usersUpdateFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
    usersDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    usersDelSuccess: (state, action) => {
      return {
        ...state,
        list: { ...state.list, data: state.list.data.filter((item) => !action.payload.data.includes(item.id)) },
        isLoading: false,
      };
    },
    usersDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const {
  usersInit,
  usersSuccess,
  usersFail,
  usersDelInit,
  usersDelSuccess,
  usersDelFail,
  usersUpdateInit,
  usersUpdateSuccess,
  usersUpdateFail,
} = usersSlice.actions;
export default usersSlice.reducer;
