import React, { useEffect, useState } from 'react';
import './AddFaultTypeForm.css';
import Button from '../../../basic/button/Button';
import Input from '../../../basic/inputs/Input/Input';
import { makeSubmittableData } from '../makeSubmittableData';
import { validation } from '../validator';
import { makeFormObjectFromArray } from '../../../../utils/utils';
import { postFormData } from '../../../../apis/apis';

export default function AddFaultTypeForm({ formAsUpdate = false }) {
  const initFormData = makeFormObjectFromArray([{ fieldName: 'name', value: '', required: true }]);
  const [formData, updateFormData] = useState(initFormData);

  const handleChange = (fieldName, data) => {
    updateFormData({ ...formData, [fieldName]: data });
  };

  const resetter = () => {
    updateFormData(initFormData);
  };

  const handleSubmit = async () => {
    let data = makeSubmittableData({
      ...formData,
    });
    if (data.status) {
      await postFormData(data.data, 'fault-type/create', resetter);
    } else {
      updateFormData({ ...data.data });
    }
  };

  //for dropdown to catch changed value

  return (
    <div className='fault-type-form w-full h-full'>
      <div className='mb-5'>
        <Input
          name='name'
          value={formData.name?.value}
          onChange={handleChange}
          validation={validation.required}
          placeholder='Type Category Name'
          className='custom-input'
          type='text'
          fieldError={formData.name?.error || false}
        />
      </div>

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass h-10 btn-normal' title='Create' />
      </div>
    </div>
  );
}
