import { getToday } from '../../../../utils/utils';

export const daySummary = [
  {
    name: 'Page A',
    uv: 5,
    pv: 10,
    amt: 2,
  },
  {
    name: 'Page B',
    uv: 4,
    pv: 2,
    amt: 7,
  },
  {
    name: 'Page C',
    uv: 3,
    pv: 7,
    amt: 5,
  },
  {
    name: 'Page D',
    uv: 6,
    pv: 3,
    amt: 2,
  },
  {
    name: 'Page E',
    uv: 9,
    pv: 4,
    amt: 2,
  },
  {
    name: 'Page F',
    uv: 6,
    pv: 3,
    amt: 2,
  },
  {
    name: 'Page G',
    uv: 4,
    pv: 3,
    amt: 1,
  },
];

export const options = [
  { id: 1, title: 'Today', value: getToday() / 1000 },
  { id: 2, title: 'Last 1 hr', value: 60 * 60 },
  { id: 3, title: 'Last 6 hrs', value: 6 * 60 * 60 },
  { id: 4, title: 'Last 24 hrs', value: 24 * 60 * 60 },
  { id: 5, title: 'Last 7 days', value: 7 * 24 * 60 * 60 },
  { id: 6, title: 'Last 15 days', value: 15 * 24 * 60 * 60 },
  { id: 7, title: 'Last 30 days', value: 30 * 24 * 60 * 60 },
  { id: 8, title: 'Last 6 month', value: 180 * 24 * 60 * 60 },
  { id: 9, title: 'Last 1 year', value: 365 * 24 * 60 * 60 },
  { id: 10, title: 'All', value: 100 * 365 * 24 * 60 * 60 },
];
