import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../slices/auth';
import { fullName } from '../../../utils/utils';
import Button from '../../basic/button/Button';
import DropDown from '../../basic/drop-down/DropDown';
import RenderTab from '../render-tab/RenderTab';
import './Sidebar.css';
import userIcon from '../../../assets/icons/userIcon.png';

export default function Sidebar() {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const rightIcon = () => <i style={{ fontSize: '14px', marginLeft: '6px' }} className='fa fa-caret-down '></i>;

  return (
    <div className='sidebar'>
      <div className='flex flex-row'>
        <div className='p-1  w-10 h-10'>
          <img className='w-full h-full rounded-full' src={user.image || userIcon} alt='profile-pic' />
        </div>
        <div className='flex items-start flex-col '>
          <h5 className='text-primary'>Welcome</h5>
          <div className='flex items-center'>
            <DropDown item={{ id: 1, title: fullName(user.data?.first_name, user.data?.last_name), rightIcon: rightIcon }}>
              <div className='p-3  dark:bg-primary-800 bg-primary-200 w-[180px] custom-shadow'>
                <ul className='text-sm'>
                  <li onClick={() => navigate('user/profile/password/change')} className='cursor-pointer my-2'>
                    Change Password
                  </li>
                  <li onClick={() => navigate('user/profile/update')} className='cursor-pointer my-2'>
                    Update Profile
                  </li>
                </ul>
                <Button className='btnClass h-8 mt-12 btn-normal' title='Logout' onClick={handleLogout} />
              </div>
            </DropDown>
          </div>
        </div>
      </div>

      <div className='mt-10 '>
        <RenderTab />
      </div>
    </div>
  );
}
