import React from 'react';
import Search from '../../basic/search-input/Search';
import './Navbar.css';
import Logo from '../../../assets/icons/think.png';
import { ReactComponent as LogoutImg } from '../../../assets/icons/logout.svg';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../slices/auth';
import { useNavigate } from 'react-router-dom';
import DarkSwitch from '../../basic/dark-switch/DarkSwitch';
import { makeHomeUrl } from '../../../utils/utils';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menuIcon.svg';

export default function Navbar({ onClick = () => null }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    user: { data },
  } = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const handleClickOnLogo = () => {
    navigate(makeHomeUrl(data.user_type));
  };

  const icons = [
    {
      id: 1,
      icon: <LogoutImg onClick={handleLogout} />,
      link: '',
    },
  ];
  return (
    <div className='navbar'>
      <div onClick={onClick} className='mr-6 inline lg:hidden cursor-pointer'>
        <MenuIcon className='w-6 rounded-full p-1 border border-primary-500' />
      </div>
      <div className='w-[250px] hidden lg:inline'>
        <img
          src={Logo}
          alt='images'
          onClick={handleClickOnLogo}
          className=' mx-auto p-3 w-24 cursor-pointer fill-primary-700 dark:fill-secondary-50'
        />
      </div>
      <Search icon placeholder='Search here' className='mr-2 custom-search hidden lg:inline' />
      <DarkSwitch className='ml-auto' />
      <ul className=' flex'>
        {icons?.map((icon) => (
          <li className='ml-2 text-primary-400' key={icon.id}>
            <span>{icon.icon}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
