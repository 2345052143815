import React, { useEffect } from 'react';
import ChatRoom from './components/ChatRoom';
import userIcon from './assets/userIcon.png';
import Search from '../../components/basic/search-input/Search';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getChatAllUser, SUPER_ADMIN } from '../../apis/apis';
import { fullName, getInActiveTimeToString } from '../../utils/utils';
import { capitalize } from '../../components/common/forms/makeSubmittableData';
import { useCallback } from 'react';

export default function ChatBoard() {
  const {
    user: { data: user },
  } = useSelector((state) => state.auth) || {};

  const [user2, setUser2] = useState(user.user_type === SUPER_ADMIN ? null : { id: user.id });
  const [users, updateUsers] = useState(null);
  const [queryObj] = useState({ room_id: '', page: 0, per_page: 20 });

  const fetchUsers = useCallback(async () => {
    const res = await getChatAllUser(queryObj);
    console.log(res);
    if (res.data.status) {
      const modifyArr = res.data.data?.map((item) => ({ ...item, notify: false }));
      updateUsers(modifyArr);
    }
  }, [queryObj]);

  useEffect(() => {
    if (user.user_type === SUPER_ADMIN) {
      fetchUsers();
    }
  }, [fetchUsers, user]);

  const clickHandleOnUser = (user) => {
    setUser2(user);
    notifyUser(user.id, false);
  };

  const notifyUser = (userId, value = true) => {
    updateUsers((users) => users?.map((u) => (u.id == userId ? { ...u, notify: value } : u)));
  };

  return (
    <div>
      <div className='flex'>
        {user?.user_type === SUPER_ADMIN && (
          <div className='w-60'>
            <div className='h-10 border border-[#8080807d]'>
              <Search icon placeholder='Search here' className='custom-search ' />
            </div>

            <ul className='chat-list  p-4 bg-white text-primary-700 dark:bg-primary-700 dark:text-primary-100'>
              {users?.map((li) => (
                <li
                  onClick={() => clickHandleOnUser(li)}
                  className={`p-2 my-1 flex items-center hover:bg-primary-200 dark:hover:bg-primary-600 ${
                    li.id === user2?.id && 'bg-primary-200 dark:bg-primary-600'
                  } `}
                  key={li.id}
                >
                  <div className='mr-2'>
                    <img className='mr-4 rounded-full w-8 h-8' src={userIcon} alt='' />
                  </div>
                  <div>
                    <h5 className={`${li.notify && 'font-black'}`}>{fullName(capitalize(li.first_name), capitalize(li.last_name))}</h5>
                    <ul className='flex items-center'>
                      <li className={`w-2 h-2 mr-2 rounded-full inline ${li.online ? 'bg-secondary-600' : 'bg-primary-500'}`}></li>
                      <p className={`${li.notify && 'font-black'}`}>{li.online ? 'Online' : `${getInActiveTimeToString(li.time)}`}</p>
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className='p-10 flex-1'>{user2 && <ChatRoom notifyUser={notifyUser} user1={user} user2={user2} />}</div>
      </div>
    </div>
  );
}
