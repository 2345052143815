import React from 'react';
import Button from '../../basic/button';
import FullCircle from '../../basic/progress/FullCircle';
import HalfCircle from '../../basic/progress/HalfCircle';
import ChartForArea from '../cards/chart-cards/area/ChartForArea';
import DeviceCardContainer from '../cards/device-log-card/DeviceLogCard';
import { getFormattedTime, getToday, timeConvert } from '../../../utils/utils';
import { daySummary } from '../../../pages/home/components/summaries/data';
import './displaySummary.css';
import FuelLevel from '../../basic/progress/FuelLevel';
import { ReactComponent as FuelIcon } from '../../../assets/icons/fuelIndicator.svg';
import { ReactComponent as AlarmIcon } from '../../../assets/icons/alarm.svg';
import PowerFactorChart from '../cards/chart-cards/power-factor/PowerFactorChart';
import SelectFromOption from '../../basic/SelectedInput/SelectedInput';
import { useState } from 'react';

export default function DisplaySummary({ item = {} }) {
  const [filterObj, updateFilterObj] = useState(options[0]);

  const handleFilterClick = (item) => {
    let endTime = timeConvert().second;
    let startTime;
    if (item.id === 1) {
      startTime = item.value;
    } else {
      startTime = endTime - item.value;
    }

    updateFilterObj(item);
    // updateQueryObject((state) => ({ ...state, start_time: startTime, end_time: endTime }));
  };

  return (
    <div className='display-summary'>
      <div className='grid grid-flow-row grid-cols-12 gap-4'>
        <div className='grid-item '>
          <DeviceCardContainer>
            <h2>Status</h2>
            <div className='absolute transform left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
              <div className='bg-secondary-600 w-16 h-6 rounded-sm shadow-light dark:shadow-dark'>
                <Button>On</Button>
              </div>
            </div>
          </DeviceCardContainer>
        </div>
        <div className='grid-item '>
          <DeviceCardContainer>
            <div className='content '>
              <h2 className=''>Current Run-Time</h2>
              <div className='w-[116px] h-11   absolute left-1/2 top-1/2 transform -translate-x-1/2  -translate-y-1/2 centerXY rounded-xl border-4 border-primary-100 dark:border-primary-700'>
                <p className='text-xl'>{item.duration ? getFormattedTime(item.duration) : '00:00:00'}</p>
              </div>
            </div>
          </DeviceCardContainer>
        </div>

        <div className='grid-item'>
          <DeviceCardContainer>
            <div className='content !p-0 absolute left-0 bottom-0 w-full'>
              {/* <h4 className=''>Monthly to Date</h4> */}
              <div className='flex justify-between items-center px-4 pt-2'>
                <h2 className=''>kWh</h2>
                <h1 className=' text-xl'>{item.energy_consumed || '00'}</h1>
              </div>
              <ChartForArea options={{ data: daySummary, fill: '#a092b0' }} className='!rounded-none'>
                <div className='content px-4 py-2'>{/* <p>{data.runningDevices ? data.runningDevices : '0'}</p> */}</div>
              </ChartForArea>

              {/* <h4 className=' w-full absolute left-0 bottom-5 text-center'>Details</h4> */}
            </div>
          </DeviceCardContainer>
        </div>
        <div className='grid-item'>
          <DeviceCardContainer>
            <div className='content'>
              <h2>Current</h2>
              <HalfCircle percentage={item.current || 0} />
              <div className='w-[116px] h-11 dark:text-white text-primary-700  absolute left-1/2 top-1/2 transform -translate-x-1/2  -translate-y-1/2 centerXY flex-col'>
                <p className='text-xl'> {item.current || '0.0'} </p>
                <h5 className=''>A</h5>{' '}
              </div>

              <h4 className='w-full absolute left-0 bottom-5 text-center'>Details</h4>
            </div>
          </DeviceCardContainer>
        </div>
        <div className='grid-item'>
          <DeviceCardContainer>
            <div className='content'>
              <h2>Fuel Level</h2>
              <FuelLevel percentage={100} strokeWidth={30} />
              <div className=' dark:text-white text-primary-700  absolute left-1/2 top-1/2 transform -translate-x-1/2  -translate-y-1/2 '>
                <div className={`transform origin-bottom-left transition-all duration-100 rotate-[${90}deg] relative left-[14px]`}>
                  <FuelIcon className='fill-black dark:fill-white w-6 ' />
                </div>
              </div>

              <h4 className='w-full absolute left-0 bottom-5 text-center'>Details</h4>
            </div>
          </DeviceCardContainer>
        </div>

        <div className=' grid-item'>
          <DeviceCardContainer>
            <div className='content'>
              {/* <h4 className=''>Monthly to Date</h4> */}
              <div className=''>
                <h2>Current Voltage</h2>
                <div className='flex justify-center'>
                  <FullCircle percentage={item.voltage?.toFixed(0) || 0}>
                    <p className='absolute text-lg left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 '>{item.voltage?.toFixed(0) || '0.0'} V </p>
                  </FullCircle>
                </div>
              </div>

              <h4 className=' w-full absolute left-0 bottom-5 text-center'>Details</h4>
            </div>
          </DeviceCardContainer>
        </div>

        <div className=' grid-item'>
          <DeviceCardContainer>
            <div className='content'>
              <div className='content !p-0  w-full'>
                <h2>Power Factor</h2>
                <div className='w-full'>
                  <PowerFactorChart />
                </div>
              </div>

              <h4 className=' w-full absolute left-0 bottom-5 text-center'>Details</h4>
            </div>
          </DeviceCardContainer>
        </div>
      </div>

      <div className='mt-5 grid grid-flow-row grid-cols-12 gap-4'>
        <div className='grid-item'>
          <DeviceCardContainer className='!aspect-[1/1.3]'>
            <div className='content'>
              <h2 className='mb-10'>Fuel Alarm</h2>
              <AlarmIcon className='mb-10 fill-black dark:fill-white w-10 mx-auto ' />

              <h4 className='mb-10 text-center'>
                At <span className='text-lg font-extrabold text-[#FDCE1B]'>25%</span> Estimated
              </h4>
              <p className='mb-10 text-center'>
                <span className='text-2xl font-extrabold text-[#FDCE1B]'>24</span> <sub className='text-sm'>Days</sub>
              </p>
            </div>
          </DeviceCardContainer>
        </div>
        <div className='grid-item'>
          <DeviceCardContainer className='!aspect-[1/1.3]'>
            <div className='content'>
              <h2 className='mb-7'>Service Due</h2>
              <div className='mb-7 w-24 mx-auto'>
                <SelectFromOption
                  headerClass='chartDropDownHeader h-8  rounded-sm  py-2'
                  options={options}
                  value={filterObj}
                  displayKey='title'
                  onClick={handleFilterClick}
                  placeholder='Filter'
                />
              </div>
              <p className='text-center'>days</p>
              <p className='text-2xl text-center font-extrabold text-[#FDCE1B]'>291</p>
            </div>
          </DeviceCardContainer>
        </div>
      </div>
    </div>
  );
}

export const options = [
  { id: 1, title: '19-01-23', value: '' },

  { id: 2, title: '20-01-23', value: 60 * 60 },
  { id: 3, title: '21-01-23', value: 6 * 60 * 60 },
  { id: 4, title: '22-01-23', value: 24 * 60 * 60 },
  { id: 5, title: '13-01-23', value: 7 * 24 * 60 * 60 },
];
