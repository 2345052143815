import React from 'react';
import ContactUsForm from '../../components/common/forms/contact-us-form/ContactUsForm';

export default function Contact() {
  return (
    <div className='contact-us max-w-md mx-auto'>
      <ContactUsForm />
    </div>
  );
}
