import React, { useState } from 'react';
import Input from '../../components/basic/inputs/Input/Input';
import LoginForm from '../../components/common/forms/login-form/LoginForm';
import './Login.css';
import loginBg from '../../assets/images/backImage.jpg';
import logoBg from '../../assets/icons/logo.svg';

export default function Login() {
  return (
    <div className='login w-screen h-screen relative'>
      <div className='flex-row h-full hidden lg:flex'>
        <div className='w-[400px] bg-black '>
          <img className='w-44 mx-auto' src={logoBg} alt='' />
        </div>
        <div className='flex-1'>
          <img className='object-cover h-full w-full' src={loginBg} alt='backImage' />
        </div>
      </div>
      <div className='form-container w-full lg:w-[400px] p-6 bg-white rounded-lg custom-shadow  absolute left-0 lg:left-28  top-24'>
        <p className='mb-12 text-primary-700 text-lg'>Login to your account</p>
        <LoginForm />
      </div>
    </div>
  );
}
