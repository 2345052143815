import React, { useState } from 'react';
import './style.css';
import SelectFromOption from '../../../../components/basic/SelectedInput/SelectedInput';
import ChartForBar from '../../../../components/common/cards/chart-cards/bar/ChartForBar';
import { optionsDate, optionsForTopSite, optionsYear, quarterData } from './data';
import TopSites from './top-sites';
import TotalDevices from './total-devices/TotalDevices';
import MiniNav from '../../../../components/common/mini-nav';
import { currentQuadrant, fillByColor, timeConvert } from '../../../../utils/utils';
import { useEffect } from 'react';

export default function QuarterSummary(props) {
  const { chartData = [], topSiteData = [], totalDevices } = props.data;
  let year = new Date().getFullYear();
  const [pickedDate, setPickedDate] = useState(optionsDate[currentQuadrant() - 1]);
  const [pickedYear, setPickedYear] = useState(optionsYear?.find((it) => it.value === year));
  const [filterObjectTopSite, updateFilterObjectTopSite] = useState([0]);

  const handleDate = (item) => {
    setPickedDate(item);
    props.updateQueryObject((state) => ({ ...state, quarter: item.value }));
  };

  const handleYear = (item) => {
    setPickedYear(item);
    props.updateQueryObject((state) => ({ ...state, quarter_date: item.value }));
  };

  const handleTopSiteClick = (item) => {
    const endTime = timeConvert().second;
    const startTime = endTime - item.value;
    updateFilterObjectTopSite(item);
    props.updateQueryObject((state) => ({ ...state, top_site_start_time: startTime, top_site_end_time: endTime }));
  };

  return (
    <div className='quarter-container'>
      <div className='grid gap-4 grid-flow-row grid-cols-12'>
        <div className='col-span-12 lg:col-span-6'>
          <ChartForBar
            data={chartData?.map((item, i) => ({
              ...item,
              fill: fillByColor(i).color,
              hour: Math.ceil(item.run_hour / 3600),
            }))}
          >
            <div className='content p-3'>
              <h3 className='text-primary'>Quarter Summary</h3>
            </div>
            <div className='absolute z-10 right-24 top-4 flex items-center'>
              <SelectFromOption
                headerClass='chartDropDownHeader w-24'
                options={optionsDate}
                onClick={handleDate}
                value={pickedDate}
                displayKey='title'
              />
            </div>

            <div className='absolute  z-10 right-4 top-4 flex items-center'>
              <SelectFromOption
                headerClass='chartDropDownHeader  w-16'
                options={optionsYear}
                onClick={handleYear}
                value={pickedYear}
                displayKey='title'
              />
            </div>
          </ChartForBar>
        </div>

        <div className=' col-span-12 lg:col-span-3 p-3 rounded-lg custom-shadow'>
          <div className='content flex items-center mb-6'>
            <h3 className='text-primary'>Top Sites</h3>
            <div className='ml-auto'>
              <SelectFromOption
                headerClass='chartDropDownHeader w-28'
                options={optionsForTopSite}
                onClick={handleTopSiteClick}
                value={filterObjectTopSite}
                displayKey='title'
              />
            </div>
          </div>

          <TopSites data={topSiteData} />
        </div>
        <div className='col-span-12 lg:col-span-3  p-3 rounded-lg custom-shadow'>
          <div className='mb-16 flex items-center justify-between'>
            <h3 className='text-primary'>Total Devices</h3>
          </div>
          <div className='flex items-center justify-center'>
            <TotalDevices data={totalDevices} />
          </div>
        </div>
      </div>
    </div>
  );
}
