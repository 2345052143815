import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import './style.css';
import { makeSubmittableData } from '../../../components/common/forms/makeSubmittableData';
import { xlsxFileReader } from '../../../utils/utils';
import { getTowerCompanies, postDataFromFile } from '../../../apis/apis';
import SelectFromOption from '../../../components/basic/SelectedInput/SelectedInput';
import Button from '../../../components/basic/button';

export default function TowerSiteFileUpload() {
  //   const [queryObj, updateQueryObject] = useState({ page: 0, per_page: 5 });
  const [selTowerCompany, setSelTowerCompany] = useState({});
  const towerCompanyList = useSelector((state) => state.towerCompanies);
  const [formData, updateFormData] = useState({ tower_company_id: { value: '', required: true } });
  const [formDataAsArr, updateFormDataAsArr] = useState([]);

  const [trackObj, updateTrackObj] = useState({});

  const handleSubmit = async () => {
    let data = makeSubmittableData(formData);
    if (data.status) {
      const result = await postDataFromFile(formDataAsArr, 'tower-sites/create');
      updateTrackObj({ ...trackObj, ...result });
      setSelTowerCompany({});
      updateFormDataAsArr([]);
    } else {
      updateFormData({ ...data.data });
    }
  };

  useEffect(() => {
    let copy = { ...formData };
    copy['tower_company_id'].value = selTowerCompany.id;
    copy['tower_company_id'].error = false;
    updateFormData({ ...copy });
  }, [selTowerCompany]);

  useEffect(() => {
    getTowerCompanies();
  }, []);

  const readUploadFile = async (e) => {
    if (e.target.files) {
      const res = await xlsxFileReader(
        e.target.files[0],
        ['name', 'code', 'address', 'latitude', 'longitude', 'site_ref', 'thana', 'district', 'region', 'category'],
        { tower_company_id: selTowerCompany.id }
      );
      updateFormDataAsArr(res);
    }
  };

  return (
    <div className='tower-site-file-upload p-4'>
      <div className='mb-5'>
        <SelectFromOption
          onClick={(op) => setSelTowerCompany(op)}
          value={selTowerCompany}
          displayKey='full_name'
          options={towerCompanyList.list || []}
          headerClass='header-class text-[13px]'
          placeholder='Select Tower Company'
          fieldError={formData.tower_company_id?.error || false}
        />
      </div>

      <div className='mb-5'>
        <input onChange={(e) => readUploadFile(e)} type='file' />
      </div>

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass h-10 btn-normal' title='Upload' />
      </div>

      <div className='response-data overflow-auto'>
        {trackObj?.duplicate?.length > 0 && (
          <p className='text-primary-light'>
            <span className='text-sm text-lime-500'>{trackObj.duplicate.join(',')}</span>
            <br /> {trackObj.duplicate.length} tower sites are already exist
          </p>
        )}
        {trackObj?.newCreate?.length > 0 && (
          <p className='text-primary-light'>
            {trackObj.newCreate?.length} <span className='text-sm text-lime-500'>are newly added successfully</span>{' '}
          </p>
        )}
        {trackObj?.error?.length > 0 && (
          <p className='text-red-500'>
            <span className='text-sm text-lime-500'>{trackObj.error.length} </span>are failed to add
          </p>
        )}
      </div>
    </div>
  );
}
