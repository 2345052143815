import React from 'react';
import PropTypes from 'prop-types';
import './CheckBox.css';

export default function CheckBox(props) {
  const { label = '', checkboxStyle, property, onClick, children } = props;
  return (
    <div onClick={onClick} className='check-box'>
      <input style={checkboxStyle} type='checkbox' property={property} />
      {label && <label className='ml-3 '>{label}</label>}
      {children && children}
    </div>
  );
}

CheckBox.propTypes = {
  label: PropTypes.string,
  checkboxStyle: PropTypes.object,
  value: PropTypes.string,
  onClick: PropTypes.func,
};
