import { useEffect, useRef, useState } from 'react';
import './progressCircle.css';

export default function ProgressCircle({
  height = 200,
  width = 200,
  barWidth = 20,
  bgOuter = 'gray',
  bgBar = 'blue',
  currentPercent = 30,
  children,
}) {
  const outerStyle = {
    position: 'relative',
    width: `${width}px`,
    height: `${height}px`,
    aspectRatio: '1/1',
    borderRadius: '50%',
    background: `conic-gradient(${bgBar} ${360 * (currentPercent / 100)}deg, ${bgOuter} 0deg)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all .5s ease-in-out',
  };
  const innerStyle = {
    position: 'absolute',
    height: `${width - barWidth}px`,
    width: `${height - barWidth}px`,
    aspectRatio: '1/1',
    borderRadius: '50%',
  };
  return (
    <div className='progress-circle' style={outerStyle}>
      <div className='innerCircle bg-white dark:bg-dark-dark' style={innerStyle}></div>
      <span className='absolute'>{children}</span>
    </div>
  );
}
