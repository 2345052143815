import React, { useEffect, useState } from 'react';
import './PgAddForm.css';

import { useSelector } from 'react-redux';
import { CUSTOMER_ADMIN, getCompanies, postFormData, SUPER_ADMIN } from '../../../../apis/apis';
import Button from '../../../basic/button/Button';
import Input from '../../../basic/inputs/Input/Input';
import SelectFromOption from '../../../basic/SelectedInput/SelectedInput';
import { makeSubmittableData } from '../makeSubmittableData';
import { validation } from '../validator';
import { makeFormObjectFromArray } from '../../../../utils/utils';

export default function PgAddForm() {
  const companyList = useSelector((state) => state.companies);
  const {
    user: {
      data: { user_type, company },
    },
  } = useSelector((state) => state.auth) || {};
  const [selCompany, setSelCompany] = useState({});
  const initFormData = makeFormObjectFromArray([
    { fieldName: 'pg_number', required: true },
    { fieldName: 'pg_name', required: true },
    { fieldName: 'address', required: false },
    { fieldName: 'city', required: false },
    { fieldName: 'company_id', value: user_type === CUSTOMER_ADMIN ? company.id : '', required: true },
  ]);
  const [formData, updateFormData] = useState(initFormData);

  const handleChange = (fieldName, data) => {
    updateFormData({ ...formData, [fieldName]: data });
  };

  const resetter = () => {
    updateFormData(initFormData);
    if (user_type === SUPER_ADMIN) {
      setSelCompany({});
    }
  };

  const handleSubmit = async () => {
    let data = makeSubmittableData({
      ...formData,
    });
    if (data.status) {
      await postFormData(data.data, 'pg-name/create', resetter);
    } else {
      updateFormData({ ...data.data });
    }
  };

  //for dropdown to catch changed value
  useEffect(() => {
    let copy = { ...formData };
    copy['company_id'].value = user_type === CUSTOMER_ADMIN ? company.id : selCompany.id;
    copy['company_id'].error = false;
    updateFormData({ ...copy });
  }, [selCompany]);

  useEffect(() => {
    if (user_type === SUPER_ADMIN) {
      getCompanies();
    }
  }, []);

  return (
    <div className='pg-add-form w-full h-full'>
      <div className='mb-5'>
        <Input
          name='pg_number'
          value={formData.pg_number?.value || ''}
          onChange={handleChange}
          validation={validation.required}
          placeholder='Type PG Number'
          className='custom-input'
          type='text'
          fieldError={formData.pg_number?.error || false}
        />
      </div>

      <div className='mb-5'>
        <Input
          name='pg_name'
          value={formData.pg_name?.value || ''}
          onChange={handleChange}
          validation={validation.required}
          placeholder='Type PG Name'
          className='custom-input'
          type='text'
          fieldError={formData.pg_name?.error || false}
        />
      </div>
      <div className='mb-5'>
        <Input
          name='address'
          value={formData.address?.value || ''}
          onChange={handleChange}
          placeholder='Type Address'
          className='custom-input'
          type='text'
        />
      </div>

      <div className='mb-5'>
        <Input
          name='city'
          value={formData.city?.value || ''}
          onChange={handleChange}
          placeholder='Type City'
          className='custom-input'
          type='text'
        />
      </div>

      <div className='mb-5'>
        <SelectFromOption
          onClick={(op) => setSelCompany(op)}
          value={user_type === CUSTOMER_ADMIN ? company : selCompany}
          options={companyList.list || []}
          displayKey='name'
          headerClass='header-class text-[13px]'
          placeholder='Select Company'
          fieldError={formData.company_id?.error || false}
          disableOption={user_type === CUSTOMER_ADMIN}
        />
      </div>

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass h-10 btn-normal' title='Create' />
      </div>
    </div>
  );
}
