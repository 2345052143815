import { createSlice } from '@reduxjs/toolkit';

const dashboard = createSlice({
  name: 'dashboard',
  initialState: { isLoading: false, list: [], isError: false },
  reducers: {
    dashboardInit: (state) => {
      return { ...state, isLoading: true };
    },
    dashboardSuccess: (state, action) => {
      return { ...state, list: action.payload.data, isLoading: false };
    },
    dashboardFail: (state, action) => {
      return { ...state, list: [], isLoading: false, isError: action.payload.data };
    },
  },
});

export const { dashboardInit, dashboardSuccess, dashboardFail } = dashboard.actions;
export default dashboard.reducer;
