import React, { useState } from 'react';
import SelectFromOption from '../../../../components/basic/SelectedInput/SelectedInput';
import './style.css';
import ChartForArea from '../../../../components/common/cards/chart-cards/area/ChartForArea';
import { daySummary, options } from './data';
import { useNavigate } from 'react-router-dom';
import { getFormattedServiceHours, getToday, timeConvert } from '../../../../utils/utils';
import { useCallback } from 'react';

export default function Summaries({ data = {}, updateQueryObject = () => {} }) {
  const [filterObj, updateFilterObj] = useState(options[0]);

  const handleFilterClick = (item) => {
    let endTime = timeConvert().second;
    let startTime;
    if (item.id === 1) {
      startTime = item.value;
    } else {
      startTime = endTime - item.value;
    }

    updateFilterObj(item);
    updateQueryObject((state) => ({ ...state, start_time: startTime, end_time: endTime }));
  };

  const navigate = useNavigate();

  return (
    <div className='summary-container'>
      <div className='grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
        <ChartForArea options={{ data: daySummary, fill: '#fac091' }} headerClass='h-20' className='custom-shadow'>
          <div className='content p-3'>
            <h3>Day Summary</h3>
            {data.totalRunTime && <p>{getFormattedServiceHours(data.totalRunTime)}</p>}
          </div>
          <div className='absolute w-[110px] z-10 right-1 top-2'>
            <div className=''>
              <SelectFromOption
                headerClass='chartDropDownHeader h-8  rounded-sm  py-2'
                options={options}
                value={filterObj}
                displayKey='title'
                onClick={handleFilterClick}
                placeholder='Filter'
              />
            </div>
          </div>
        </ChartForArea>
        <div className='!cursor-pointer' onClick={() => navigate('device/active-devices')}>
          <ChartForArea options={{ data: daySummary, fill: '#a092b0' }} headerClass='h-20' className='custom-shadow'>
            <div className='content p-3'>
              <h3 className='text-primary'>Running PG</h3>
              <p>{data.runningDevices ? data.runningDevices : '0'}</p>
            </div>
          </ChartForArea>
        </div>

        <ChartForArea options={{ data: daySummary, fill: '#92cddc' }} headerClass='h-20' className='custom-shadow'>
          <div className='content p-3'>
            <h3 className='text-primary'>Monthly Operating</h3>
          </div>
        </ChartForArea>
        <ChartForArea options={{ data: daySummary, fill: '#95b3d7' }} headerClass='h-20' className='custom-shadow'>
          <div className='content p-3'>
            <h3 className='text-primary'> Energy consumed (This month) </h3>
            {<p>{data.monthlyKVA?.kwh || 0} KWh</p>}
          </div>
        </ChartForArea>
      </div>
    </div>
  );
}
