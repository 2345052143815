import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

export default function DarkSwitch({ className }) {
  const theme = localStorage.getItem('theme');
  const [dark, setDark] = useState(theme === 'dark' ? true : false);

  useEffect(() => {
    if (dark) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  }, [dark]);

  return (
    <div
      onClick={() => setDark(!dark)}
      className={`transition-all duration-300 p-2 relative w-10 h-6 rounded-lg ${className} ${
        dark ? 'bg-secondary-500 justify-end' : 'bg-primary-300'
      }`}
    >
      <div
        className={`w-2/5 absolute left-1 top-1/2 -translate-y-1/2 aspect-square transition-all duration-300 rounded-full bg-primary-100 ${
          dark && 'left-[calc(50%-4px)]'
        }`}
      ></div>
    </div>
  );
}
