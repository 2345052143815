import React from 'react';
import PropTypes from 'prop-types';

export default function DisplayItems({ items = [], children: View }) {
  return (
    <div className='grid grid-flow-row xs:grid-cols-1 sm:grid-cols-2  xl:grid-cols-3 2xl:grid-cols-4 gap-2  place-items-center place-content-center'>
      {items?.map((item) => (
        <View key={item.id} item={item} />
      ))}
    </div>
  );
}

DisplayItems.propTypes = {
  items: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired,
};
