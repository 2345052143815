import React, { useState } from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '../../basic/button';
import './DateRangePicker.css';

export default function DateRangePicker({ onApply = () => {} }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <div className=''>
      <div className='date-picker flex items-center'>
        <div className=''>
          <DatePicker
            className='input-wrapper'
            placeholderText='Select Start Date'
            showTimeSelect
            dateFormat='MMMM d, yyyy h:mmaa'
            selected={startDate}
            startDate={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </div>
        <div className='ml-4'>
          <DatePicker
            className='input-wrapper'
            placeholderText='Select End Date'
            showTimeSelect
            dateFormat='MMMM d, yyyy h:mmaa'
            selected={endDate}
            startDate={startDate}
            minDate={startDate}
            onChange={(date) => setEndDate(date)}
          />
        </div>
      </div>
      <div className='w-28 ml-auto mt-16'>
        <Button
          onClick={() => onApply({ startDate: new Date(startDate).getTime(), endDate: new Date(endDate).getTime() })}
          title='Apply'
          className='btnClass btn-success  h-8 px-2'
        />
      </div>
    </div>
  );
}
