import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: {}, isLoading: false, isError: false },
  reducers: {
    login: (state, action) => {
      return { ...state, ...action.payload };
    },
    logout: (state, action) => {
      localStorage.removeItem('token');
      return { ...state, user: {}, isError: false };
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
