import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CUSTOMER_ADMIN, CUSTOMER_USER, deletePgs, getCompanies, getPgs, getUsers, postFormData, SUPER_ADMIN } from '../../../apis/apis';
import Button from '../../../components/basic/button';
import CheckBox from '../../../components/basic/check-box/CheckBox';
import Modal from '../../../components/basic/Modals/modal with create Portal/Modal';
import SelectFromOption from '../../../components/basic/SelectedInput/SelectedInput';
import { makeSubmittableData } from '../../../components/common/forms/makeSubmittableData';
import { checkEmptyObject } from '../../../components/common/forms/validator';
import ModalDelConfirmation from '../../../components/common/modal-del-confirmation';
import NoData from '../../../components/common/no-data/NoData';
import { PaginatedItems } from '../../../components/common/pagination/Paginate';
import Suspender from '../../../components/common/suspender/Suspender';
import { makeFormObjectFromArray } from '../../../utils/utils';
import './style.css';

export default function PGList() {
  const { isLoading, isError, list } = useSelector((state) => state.pgs);
  const users = useSelector((state) => state.users);
  const companies = useSelector((state) => state.companies);

  const {
    user: {
      data: { id, user_type, company },
    },
  } = useSelector((state) => state.auth) || {};

  const [queryObj, updateQueryObject] = useState({
    page: 0,
    per_page: 10,
  });

  const [queryObjUser, updateQueryObjectUser] = useState({
    page: 0,
    per_page: 10,
  });
  const [modalItem, setModalItem] = useState(null);
  const [assignModal, setAssignModal] = useState(false);
  const [selCompany, setSelCompany] = useState({});
  const [selUser, setSelUser] = useState({});

  const [formDataAsArr, updateFormDataAsArr] = useState([]);
  const [formData, updateFormData] = useState(
    makeFormObjectFromArray([
      { fieldName: 'company_id', value: '', required: true },
      { fieldName: 'user_id', value: '', required: true },
    ])
  );

  const [reset, setReset] = useState(false);

  const handleConfirm = () => {
    deletePgs([modalItem.pg_number]);
    setModalItem(null);
  };

  useEffect(() => {
    const elements = document.querySelectorAll(`input[type='checkbox']`);
    for (let i = 0; i < elements.length; i++) {
      elements[i].checked = reset;
    }
  }, [reset]);

  const handleAssignModal = () => {
    const elements = document.querySelectorAll(`tbody input[type='checkbox']`);
    let tempArr = [];
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].checked) {
        tempArr.push({
          pg_number: elements[i].getAttribute('property'),
        });
      }
    }
    updateFormDataAsArr(tempArr);
    setAssignModal(true);
  };

  const handleAssignToUser = async () => {
    let data = makeSubmittableData(formData);
    if (data.status) {
      await postFormData(
        formDataAsArr?.map((ar) => {
          return { ...ar, user_id: selUser.id };
        }),
        'user-pg-name/create'
      );
      setAssignModal(false);
      setSelUser({});
      setSelCompany({});
    } else {
      updateFormData({ ...data.data });
    }
  };

  useEffect(() => {
    let copy = { ...formData };
    copy['company_id'].value = user_type === CUSTOMER_ADMIN ? company.id : selCompany.id || '';
    copy['company_id'].error = false;
    updateFormData({ ...copy });
    setSelUser({});
  }, [selCompany]);

  useEffect(() => {
    let copy = { ...formData };
    copy['user_id'].value = selUser.id || '';
    copy['user_id'].error = false;
    updateFormData({ ...copy });
  }, [selUser]);

  useEffect(() => {
    if (user_type === SUPER_ADMIN) {
      getPgs({ ...queryObj });
    } else if (user_type === CUSTOMER_ADMIN) {
      getPgs({ ...queryObj, company_id: company.id });
    } else {
      getPgs({ ...queryObj, user_id: id });
    }
  }, [queryObj]);

  useEffect(() => {
    if (user_type === SUPER_ADMIN) {
      getCompanies();
    }
  }, []);

  useEffect(() => {
    if (user_type === CUSTOMER_ADMIN) {
      getUsers({ ...queryObjUser, company_id: company.id });
    } else if (user_type === SUPER_ADMIN && selCompany.id) {
      getUsers({ ...queryObjUser, company_id: selCompany.id });
    }
  }, [selCompany.id]);

  return (
    <Suspender isLoading={isLoading} isError={isError}>
      {modalItem && (
        <Modal onClose={() => setModalItem(null)}>
          <ModalDelConfirmation onCancel={() => setModalItem(null)} onConfirm={handleConfirm} />
        </Modal>
      )}
      {assignModal && (
        <Modal onClose={() => setAssignModal(false)}>
          <div className='max-w-sm mx-auto bg-primary-50 p-4 rounded-md'>
            {user_type === SUPER_ADMIN && (
              <div className='mb-5'>
                <SelectFromOption
                  onClick={(op) => setSelCompany(op)}
                  value={selCompany || ''}
                  displayKey='name'
                  options={companies.list || []}
                  headerClass=' border h-10 rounded-lg border-primary-300  text-primary-700 text-base'
                  placeholder='Select Company'
                  fieldError={formData.company_id?.error || false}
                />
              </div>
            )}
            <div className='mb-5'>
              <SelectFromOption
                onClick={(op) => setSelUser(op)}
                value={selUser || ''}
                displayKey='first_name'
                options={users?.list.data?.filter((li) => li.user_type.id === CUSTOMER_USER) || []}
                headerClass=' border h-10 rounded-lg border-primary-300  text-primary-700 text-base'
                placeholder='Select User'
                fieldError={formData.user_id?.error || false}
              />
            </div>
            <Button className='btnClass  mx-auto h-10 btn-normal' onClick={handleAssignToUser} title='Assign' />
          </div>
        </Modal>
      )}
      <div className='tower-sites p-4'>
        <div className='table-container h-[530px] overflow-y-auto'>
          <table className='w-full text-center text-primary-dark '>
            <thead className='h-12 '>
              <tr>
                {[SUPER_ADMIN, CUSTOMER_ADMIN].includes(user_type) && (
                  <th>
                    <CheckBox onClick={() => setReset(!reset)} className='w-4 h-4 bg-pink-300' />
                  </th>
                )}
                <th>
                  <pre>PG Number</pre>
                </th>
                <th>
                  <pre>PG Name </pre>
                </th>
                <th>
                  <pre>Address</pre>
                </th>
                <th>
                  <pre>Company Id</pre>
                </th>
                <th>
                  <pre>Device Id</pre>
                </th>
                {[SUPER_ADMIN, CUSTOMER_ADMIN].includes(user_type) && (
                  <th>
                    <pre>Action</pre>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {list.data?.map((item) => (
                <tr key={item.pg_number}>
                  {[SUPER_ADMIN, CUSTOMER_ADMIN].includes(user_type) && (
                    <td>
                      <CheckBox property={item.pg_number} className='w-4 h-4 bg-pink-300' />
                    </td>
                  )}

                  <td className=''>{item.pg_number || '-'}</td>
                  <td>{item.pg_name || '-'}</td>
                  <td>{item.address + ',' + item.city || '-'}</td>

                  <td>{item.company_id || '-'}</td>
                  <td>{item.device_id || 'Not Assigned'}</td>
                  {[SUPER_ADMIN, CUSTOMER_ADMIN].includes(user_type) && (
                    <td>
                      <div className='flex items-center justify-center gap-1 h-8'>
                        {/* <h4 className='w-12 rounded-md h-full cursor-pointer border border-[#28a745] flex items-center justify-center'>
                          Edit
                        </h4> */}
                        <h4
                          onClick={() => setModalItem(item)}
                          className='w-12 rounded-md h-full cursor-pointer border border-[#dc3545] flex items-center justify-center'
                        >
                          delete
                        </h4>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {list.data?.length === 0 && <NoData />}
          {[SUPER_ADMIN, CUSTOMER_ADMIN].includes(user_type) && (
            <div className='mt-4 w-40 ml-auto'>
              <Button onClick={handleAssignModal} className='h-10 btnClass btn-normal' title='Assign to User' />
            </div>
          )}
        </div>

        <div className='my-4'>
          <PaginatedItems
            itemsPerPage={queryObj.per_page}
            onClick={(parm) => updateQueryObject({ ...queryObj, page: parm })}
            totalItems={list.pagination?.total}
          />
        </div>
      </div>
    </Suspender>
  );
}
