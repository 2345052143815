import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import './style.css';
import { makeSubmittableData } from '../../../components/common/forms/makeSubmittableData';
import { makeFormObjectFromArray, xlsxFileReader } from '../../../utils/utils';
import { getCompanies, postDataFromFile } from '../../../apis/apis';
import SelectFromOption from '../../../components/basic/SelectedInput/SelectedInput';
import Button from '../../../components/basic/button';

export default function DeviceFileUpload() {
  //   const [queryObj, updateQueryObject] = useState({ page: 0, per_page: 5 });
  const [selCompany, setSelCompany] = useState({});
  const companyList = useSelector((state) => state.companies);
  const [formDataAsArr, updateFormDataAsArr] = useState([]);
  const [trackObj, updateTrackObj] = useState({});
  const [formData, updateFormData] = useState(makeFormObjectFromArray([{ fieldName: 'company_id' }]));

  const handleSubmit = async () => {
    let data = makeSubmittableData(formData);
    if (data.status) {
      const result = await postDataFromFile(formDataAsArr, 'think-device/create');
      updateTrackObj({ ...trackObj, ...result });
      setSelCompany({});
      updateFormDataAsArr([]);
    } else {
      updateFormData({ ...data.data });
    }
  };
  useEffect(() => {
    let copy = { ...formData };
    copy['company_id'].value = selCompany.id;
    copy['company_id'].error = false;
    updateFormData({ ...copy });
  }, [selCompany]);

  useEffect(() => {
    getCompanies();
  }, []);

  const readUploadFile = async (e) => {
    if (e.target.files) {
      const res = await xlsxFileReader(
        e.target.files[0],
        ['device_id', 'imei', 'hardware_version', 'hardware_version', 'device_batch_no'],
        { company_id: selCompany.id }
      );
      updateFormDataAsArr(res);
    }
  };

  return (
    <div className='tower-site-file-upload p-4'>
      <div className='mb-5'>
        <SelectFromOption
          onClick={(op) => setSelCompany(op)}
          value={selCompany}
          displayKey='name'
          options={companyList.list || []}
          headerClass='header-class text-[13px]'
          placeholder='Select Tower Company'
          fieldError={formData.company_id?.error || false}
        />
      </div>

      <div className='mb-5'>
        <input onChange={(e) => readUploadFile(e)} type='file' />
      </div>

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass h-10 btn-normal' title='Upload' />
      </div>

      <div className='response-data overflow-auto'>
        {trackObj?.duplicate?.length > 0 && (
          <p className='text-primary-light'>
            <span className='text-sm text-lime-500'>{trackObj.duplicate.join(',')}</span>
            <br /> {trackObj.duplicate.length} tower sites are already exist
          </p>
        )}
        {trackObj?.newCreate?.length > 0 && (
          <p className='text-primary-light'>
            {trackObj.newCreate?.length} <span className='text-sm text-lime-500'>are newly added successfully</span>{' '}
          </p>
        )}
        {trackObj?.err && <p className='text-red-500'>{trackObj.err}</p>}
      </div>
    </div>
  );
}
