import React from 'react';
import { AreaChart, Area, Tooltip, XAxis, YAxis, ResponsiveContainer, BarChart, CartesianGrid, Legend, Bar } from 'recharts';
import { getMaxFromArrayOfObject } from '../../../../../utils/utils';
import './style.css';
import CustomTooltip from './tooltip';

export default function ChartForBar({ options = {}, children, data = [], charHeight = 300 }) {
  return (
    <div className='chart-card-bar relative'>
      {data.length === 0 && (
        <div className='no-data w-[300px] text-center absolute left-[56%] top-1/2 -translate-x-1/2 -translate-y-1/2'>
          There is currently no data available for the parameters selected. Please Try a different quarter.
        </div>
      )}
      <div className='h-20'>{children}</div>
      <div className='chart-container'>
        <ResponsiveContainer width='100%' height={charHeight}>
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' vertical={false} />
            <XAxis dataKey='month' tick={data.length === 0 ? false : true} />
            <YAxis type='number' dataKey='hour' domain={[0, 'dataMax + 10']} />
            <Tooltip content={<CustomTooltip />} />
            {/* <Legend /> */}
            <Bar dataKey='hour' barSize={50} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

// const data = [
//   {
//     id: 1,
//     name: 'Jan',
//     uv: 4000,
//     fill: '#70BDD1',
//   },
//   {
//     id: 2,
//     name: 'Feb',
//     uv: 3500,
//     fill: '#b0c978',
//   },
//   {
//     id: 3,
//     name: 'Mar',
//     uv: 2200,
//     fill: '#f9ab6b',
//   },
// ];
