import React from 'react';
import Button from '../../basic/button';

export default function ModalDelConfirmation({
  label = 'Are you want to delete?',
  onCancel = () => {},
  onConfirm = () => {},
  children,
}) {
  return (
    <div className='transform absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 aspect-[1.3/1] h-[40vh] min-h-[200px] rounded-xl bg-white mx-auto'>
      {children ? children : <p className='mt-[20%] mx-auto max-w-[250px] text-center  text-2xl'>{label}</p>}
      <div className='px-2 w-full flex gap-2 justify-around absolute bottom-4'>
        <Button onClick={onCancel} title='Cancel' className='btnClass btn-success h-8 px-2' />
        <Button onClick={onConfirm} title='Confirm' className='btnClass btn-danger h-8 px-2' />
      </div>
    </div>
  );
}
