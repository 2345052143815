import React from 'react';
import { ReactComponent as Icon } from './noDataIcon.svg';

export default function NoData() {
  return (
    <div className='w-96 my-10 mx-auto flex items-center flex-col'>
      <Icon className='w-10 ' />
      <p className='text-base'>No Data Found</p>
    </div>
  );
}
