import dayjs from 'dayjs';
import * as xlsx from 'xlsx';

export function isMatchWith(test1, text2) {
  return test1.toLowerCase().match(text2.toLowerCase());
}

export function fullName(firstName, lastName) {
  return firstName + ' ' + lastName;
}

export function setFormValueToEmpty(formData) {
  for (const key in formData) {
    formData[key].value = '';
  }
  return formData;
}

export function getUserType(id = 3) {
  if (id === 1) return 'super-admin';
  else if (id === 2) return 'customer-admin';
  else if (id === 3) return 'customer-user';
}

export function makeObjectAsGroupFromArray(arr = [], groupSize = 100) {
  const arrLength = arr.length;
  const numberOfArr = Math.ceil(arrLength / groupSize);

  let groupObj = {};
  for (let i = 0; i < numberOfArr; i++) {
    let startIndex = i * groupSize;
    let endIndex = startIndex + groupSize;
    let temp = arr.slice(startIndex, endIndex);
    groupObj[i] = temp;
  }

  return groupObj;
}

export function makeFormObjectFromArray(arr = [{ fieldName: '', required: true }]) {
  let obj = {};
  arr.forEach((e, i) => {
    obj[e.fieldName] = { value: e.value || '', required: e.required || false };
  });
  return obj;
}

export async function xlsxFileReader(file, keys = [], options = {}) {
  const promise = new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    const fn = () => {
      const data = reader.result;
      const workbook = xlsx.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_json(worksheet);
      let fileKeys = Object.keys(json[0]);
      let modifyArr = json.map((item) => {
        let temp = { ...options };
        for (let key of keys) {
          temp[key] = item[fileKeys.find((k) => k.includes(key))] || '';
        }
        return temp;
      });
      return modifyArr;
    };

    reader.onload = () => resolve(fn());
    reader.onerror = () => reject(() => console.log('rejected'));
  });
  return promise;
}

export const makeHomeUrl = (user_type_id) => {
  if (user_type_id === 1) return '/super-admin';
  else if (user_type_id === 2) return '/customer-admin';
  else return '/customer-user';
};

export function parseDateTime(seconds) {
  let tem = dayjs(seconds * 1000);

  let parseDate = {
    date: dayjs(tem).format('MMM D, YYYY'),
    time: dayjs(tem).format('hh:mm a'),
  };
  return parseDate;
}

export function timeConvert(timeInMimSec = null) {
  const currentTime = timeInMimSec || new Date().getTime();
  return {
    mmSecond: currentTime,
    second: Math.ceil(currentTime / 1000),
    hour: Math.ceil(currentTime / (1000 * 3600)),
  };
}

export function getToday() {
  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);
  return new Date(startOfDay).getTime();
}

export function currentQuadrant() {
  const month = new Date().getMonth();
  if ([0, 1, 2].includes(month)) return 1;
  else if ([3, 4, 5].includes(month)) return 2;
  else if ([6, 7, 8].includes(month)) return 3;
  else if ([9, 10, 11].includes(month)) return 4;
  else return 1;
}

export const getFormattedServiceHours = (second) => {
  let formatHour;
  if (second < 60) {
    formatHour = second + ' Seconds';
  } else {
    const seconds = second % 60;
    const minutes = Math.floor(second / 60);
    if (minutes < 60) {
      formatHour = minutes + ' min ' + seconds + ' sec';
    } else {
      const remainingMinutes = minutes % 60;
      const hours = Math.floor(minutes / 60);
      formatHour = hours + ' h ' + remainingMinutes + ' m ' + seconds + ' s';
    }
  }
  return formatHour;
};

export function fillByColor(index) {
  if (index === 0) return { color: '#0C9EE1', bgColor: '#D2E8F6' };
  else if (index === 1) return { color: '#A67EF8', bgColor: '#EFE8F9' };
  else if (index === 2) return { color: '#EFBC26', bgColor: '#FCF2D5' };
  return { color: '#7FA63C', bgColor: '#E8EBD6' };
}

export function getMaxFromArrayOfObject(arr = [], key) {
  let values = arr.map((item) => item[key]);
  return Math.max(...values);
}

export function getInActiveTimeToString(timeInSeconds) {
  const preTime = new Date(timeInSeconds * 1000);
  const currentTime = new Date();
  const year = currentTime.getFullYear() - preTime.getFullYear();
  if (year > 0) return `${year} yr ago`;
  const month = currentTime.getMonth() - preTime.getMonth();
  if (month > 0) return `${month} month ago`;
  const date = currentTime.getDate() - preTime.getDate();
  if (date > 0) return `${date} day ago`;
  const hours = currentTime.getHours() - preTime.getHours();
  if (hours > 0) return `${hours} hours ago`;

  const minutes = currentTime.getMinutes() - preTime.getMinutes();
  if (minutes > 0) return `${minutes} min ago`;

  const seconds = currentTime.getSeconds() - preTime.getSeconds();
  if (seconds > 0) return `${seconds} sec ago`;
}

export function makeScrollTo(ref, position: 'top') {
  if (position === 'bottom') {
    const { scrollHeight, clientHeight } = ref.current;
    ref.current.scrollTop = scrollHeight - clientHeight;
  } else {
    ref.current.scrollTop = 0;
  }
}

export function getTineString(value) {
  if (value < 10) return `0${value}`;
  else return value;
}

export const getFormattedTime = (second) => {
  let formatHour;
  if (second < 60) {
    formatHour = '00:00:' + getTineString(second);
  } else {
    const seconds = second % 60;
    const minutes = Math.floor(second / 60);
    if (minutes < 60) {
      formatHour = '00:' + getTineString(minutes) + ':' + getTineString(seconds);
    } else {
      const remainingMinutes = minutes % 60;
      const hours = Math.floor(minutes / 60);
      formatHour = getTineString(hours) + ':' + getTineString(remainingMinutes) + ':' + getTineString(seconds);
    }
  }
  return formatHour;
};

export function checkEmpty(data = '' || [] || {}) {
  const convertToStr = data?.toString() || '';
  return convertToStr.length > 0 ? false : true;
}
