import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CUSTOMER_ADMIN, getDevices, getPgs, postFormData, SUPER_ADMIN } from '../../../apis/apis';
import Button from '../../../components/basic/button';
import SelectFromOption from '../../../components/basic/SelectedInput/SelectedInput';
import { makeSubmittableData } from '../../../components/common/forms/makeSubmittableData';
import { checkEmptyArray } from '../../../components/common/forms/validator';
import { makeFormObjectFromArray } from '../../../utils/utils';
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross.svg';

import './style.css';

export default function PGAttach() {
  const {
    user: {
      data: { id, user_type, company },
    },
  } = useSelector((state) => state.auth) || {};
  const pgs = useSelector((state) => state.pgs);
  const devices = useSelector((state) => state.devices);
  const [selPG, setSelPg] = useState({});
  const [selDevice, setSelDevice] = useState({});
  const initialState = makeFormObjectFromArray([
    { fieldName: 'pg_number', required: true },
    { fieldName: 'device_id', required: true },
  ]);
  const [formData, updateFormData] = useState(initialState);
  const [formDataAsArray, updateFormDataAsArr] = useState([]);
  const picked_pg_numbers = formDataAsArray?.map((temp) => temp.pg_number) || [];
  const picked_device_ids = formDataAsArray?.map((temp) => temp.device_id) || [];
  const [queryObj, updateQueryObject] = useState({});

  const handleSubmit = async () => {
    await postFormData(formDataAsArray, 'pg-name/update');
    updateFormDataAsArr([]);
  };

  const onClickToAdd = () => {
    let data = makeSubmittableData({
      ...formData,
    });
    if (data.status) {
      updateFormDataAsArr([...formDataAsArray, data.data]);
      setSelDevice({});
      setSelPg({});
    } else {
      updateFormData({ ...data.data });
    }
  };

  useEffect(() => {
    let copy = { ...formData };
    copy['pg_number'].value = selPG.pg_number;
    copy['pg_number'].error = false;
    copy['device_id'].value = selDevice.device_id;
    copy['device_id'].error = false;
    updateFormData({ ...copy });
  }, [selDevice, selPG]);

  const deleteRow = (item) => {
    updateFormDataAsArr(() => formDataAsArray.filter((temp) => temp.pg_number !== item.pg_number));
  };

  useEffect(() => {
    if (user_type === SUPER_ADMIN) {
      getDevices();
      getPgs({ ...queryObj });
    } else if (user_type === CUSTOMER_ADMIN) {
      getDevices({ company_id: company.id });
      getPgs({ ...queryObj, company_id: company.id });
    } else {
      getDevices({ company_id: company.id, user_id: id });
      getPgs({ ...queryObj, user_id: id });
    }
  }, []);

  return (
    <div className='pg-attach'>
      <div className='max-w-lg mx-auto'>
        <div className='relative w-max'>
          <div className=' flex flex-col md:flex-row items-start gap-4 justify-center'>
            <div className=''>
              <label>Select PG:</label>
              <SelectFromOption
                onClick={(op) => setSelPg(op)}
                displayKey='pg_number'
                keyValue='pg_number'
                value={selPG}
                options={pgs.list?.data?.filter((item) => !picked_pg_numbers.includes(item.pg_number)) || []}
                headerClass='header-class text-[13px]'
                placeholder='Select PG'
                fieldError={formData.pg_number?.error || false}
                readOnly={false}
              />
            </div>
            <div className=''>
              <label>Select Device:</label>
              <SelectFromOption
                onClick={(op) => setSelDevice(op)}
                value={selDevice}
                keyValue='device_id'
                displayKey='device_id'
                options={devices.list?.data?.filter((item) => !picked_device_ids.includes(item.device_id)) || []}
                headerClass='header-class text-[13px]'
                placeholder='Select Device'
                fieldError={formData.device_id?.error || false}
              />
            </div>
          </div>
          <div className='w-24 absolute -bottom-16 md:top-6 md:-right-32 '>
            <Button onClick={onClickToAdd} className='btnClass h-10 btn-normal' title='add' />
          </div>
        </div>
        {!checkEmptyArray(formDataAsArray) && (
          <div className='table-container p-6'>
            <table className='w-full text-center '>
              <thead>
                <tr>
                  <th>PG Number</th>
                  <th>Device ID</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {formDataAsArray.map((item) => (
                  <tr key={item.pg_number}>
                    <td>{item.pg_number}</td>
                    <td>{item.device_id}</td>
                    <td>
                      <CrossIcon
                        onClick={() => deleteRow(item)}
                        className='cursor-pointer w-3 h-3 rounded-full fill-primary-700 dark:fill-primary-200'
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className='mt-6 w-32 ml-auto'>
              <Button onClick={handleSubmit} className='btnClass h-10 btn-normal' title='submit' />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
