import { createSlice } from '@reduxjs/toolkit';

const ticketsSlice = createSlice({
  name: 'tickets',
  initialState: { isLoading: false, list: [], isError: false },
  reducers: {
    ticketsInit: (state) => {
      return { ...state, isLoading: true };
    },
    ticketsSuccess: (state, action) => {
      return { ...state, list: action.payload.data, isLoading: false };
    },
    ticketsFail: (state, action) => {
      return { ...state, list: [], isLoading: false, isError: action.payload.data };
    },
    ticketsDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    ticketsDelSuccess: (state, action) => {
      return {
        ...state,
        list: { ...state.list, data: state.list.data.filter((item) => !action.payload.data.includes(item.id)) },
        isLoading: false,
      };
    },
    ticketsDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { ticketsInit, ticketsSuccess, ticketsFail, ticketsDelInit, ticketsDelSuccess, ticketsDelFail } = ticketsSlice.actions;
export default ticketsSlice.reducer;
