import React from 'react';
import PropTypes from 'prop-types';

export default function HomeTile(props) {
  const { label } = props;
  return (
    <div className='flex items-center'>
      <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' className='w-4 h-4'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25'
        />
      </svg>
      <h4 className='ml-1 flex-1 '>{label}</h4>
      <div className='w-52 flex justify-between'>
        <h4 className='mini-card '>Global</h4>
        <h4 className='mini-card '>Zone</h4>
        <h4 className='mini-card '>Site</h4>
      </div>
    </div>
  );
}

HomeTile.propTypes = {
  label: PropTypes.string,
};
