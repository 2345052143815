import React, { useEffect, useState } from 'react';
import './CreateTowerSiteForm.css';

import { useSelector } from 'react-redux';
import { getTowerCompanies, postFormData } from '../../../../apis/apis';
import Button from '../../../basic/button/Button';
import Input from '../../../basic/inputs/Input/Input';
import SelectFromOption from '../../../basic/SelectedInput/SelectedInput';
import { makeSubmittableData } from '../makeSubmittableData';
import { formRest, validation } from '../validator';

export default function CreateTowerSiteForm() {
  const towerCompanyList = useSelector((state) => state.towerCompanies);
  const [selTowerCompany, setSelTowerCompany] = useState({});
  const initFormData = {
    name: { value: '', required: true },
    code: { value: '', required: true },
    address: { value: '' },
    latitude: { value: '', required: true },
    longitude: { value: '', required: true },
    site_ref: { value: '' },
    thana: { value: '' },
    district: { value: '' },
    region: { value: '' },
    category: { value: '' },
    tower_company_id: { value: '', required: true },
  };
  const [formData, updateFormData] = useState(initFormData);

  const handleChange = (fieldName, data) => {
    updateFormData({ ...formData, [fieldName]: data });
  };

  const handleSubmit = async () => {
    let data = makeSubmittableData({
      ...formData,
    });
    if (data.status) {
      await postFormData(data.data, 'tower-sites/create', resetter);
    } else {
      updateFormData({ ...data.data });
    }
  };

  //for dropdown to catch changed value
  useEffect(() => {
    let copy = { ...formData };
    copy['tower_company_id'].value = selTowerCompany.id;
    copy['tower_company_id'].error = false;
    updateFormData({ ...copy });
  }, [selTowerCompany]);

  const resetter = () => {
    updateFormData(initFormData);
    setSelTowerCompany({});
  };

  useEffect(() => {
    getTowerCompanies();
  }, []);

  return (
    <div className='create-tower-site-form w-full h-full'>
      <div className='mb-5'>
        <Input
          name='name'
          value={formData.name?.value || ''}
          onChange={handleChange}
          validation={validation.required}
          placeholder='Type Site Name'
          className='custom-input'
          type='text'
          fieldError={formData.name?.error || false}
        />
      </div>

      <div className='mb-5'>
        <Input
          name='code'
          validation={validation.required}
          value={formData.code?.value || ''}
          onChange={handleChange}
          placeholder='Type Tower Site Code'
          className='custom-input'
          type='text'
          fieldError={formData.code?.error || false}
        />
      </div>
      <div className='mb-5'>
        <Input
          name='address'
          value={formData.address?.value || ''}
          onChange={handleChange}
          placeholder='Type Address'
          className='custom-input'
          type='text'
          fieldError={formData.address?.error || false}
        />
      </div>

      <div className='mb-5'>
        <Input
          name='latitude'
          validation={validation.number}
          value={formData.latitude?.value || ''}
          onChange={handleChange}
          placeholder='Type Latitude'
          className='custom-input'
          type='text'
          fieldError={formData.latitude?.error || false}
        />
      </div>

      <div className='mb-5'>
        <Input
          name='longitude'
          validation={validation.number}
          value={formData.longitude?.value || ''}
          onChange={handleChange}
          placeholder='Type Longitude'
          className='custom-input'
          type='text'
          fieldError={formData.longitude?.error || false}
        />
      </div>

      <div className='mb-5'>
        <Input
          name='site_ref'
          value={formData.site_ref?.value || ''}
          onChange={handleChange}
          placeholder='Type Site Ref'
          className='custom-input'
          type='text'
          fieldError={formData.site_ref?.error || false}
        />
      </div>
      <div className='mb-5'>
        <Input
          name='thana'
          value={formData.thana?.value || ''}
          onChange={handleChange}
          placeholder='Type Thana'
          className='custom-input'
          type='text'
          fieldError={formData.thana?.error || false}
        />
      </div>
      <div className='mb-5'>
        <Input
          name='district'
          value={formData.district?.value || ''}
          onChange={handleChange}
          placeholder='Type District'
          className='custom-input'
          type='text'
          fieldError={formData.district?.error || false}
        />
      </div>

      <div className='mb-5'>
        <Input
          name='region'
          value={formData.region?.value || ''}
          onChange={handleChange}
          placeholder='Type Region'
          className='custom-input'
          type='text'
          fieldError={formData.region?.error || false}
        />
      </div>
      <div className='mb-5'>
        <Input
          name='category'
          value={formData.category?.value || ''}
          onChange={handleChange}
          placeholder='Type Category'
          className='custom-input'
          type='text'
          fieldError={formData.category?.error || false}
        />
      </div>

      <div className='mb-5'>
        <SelectFromOption
          onClick={(op) => setSelTowerCompany(op)}
          displayKey='full_name'
          options={towerCompanyList.list || []}
          value={selTowerCompany}
          headerClass='header-class text-[13px]'
          placeholder='Select Tower Company'
          fieldError={formData.tower_company_id?.error || false}
        />
      </div>

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass h-10 btn-normal' title='Create' />
      </div>
    </div>
  );
}
