import React, { useRef } from 'react';
import MiniNav from '../../../components/common/mini-nav/index';
import './ChatRoom.css';
import MassageBox from './MassageBox';
import userPng from '../assets/userIcon.png';
import { useState } from 'react';

import { useEffect } from 'react';
import TextBox from './TextBox';
import { socket } from '../socket';
import { getChatMessages } from '../../../apis/apis';
import { makeScrollTo } from '../../../utils/utils';

function ChatRoom({ user1 = {}, user2 = {}, notifyUser = () => {} }) {
  const [messages, setMessages] = useState([]);
  const [queryObj] = useState({ page: 0, per_page: 20 });
  const scrollRef = useRef();

  const getPrevMessages = async (parm) => {
    const res = await getChatMessages(parm);
    // console.log(res);
    if (res.data.status) {
      const newMsg = res.data.data?.filter((msg) => msg.r === `${user2.id}`);
      setMessages(() => newMsg?.reverse((a, b) => a > b));
    }
  };

  useEffect(() => {
    socket.on('connection', () => {
      console.log('socket id', socket.id);
      socket.emit('channel', { user_id: user1.id, socket_id: socket.id, online: 1 });
    });

    socket.on('message', (message) => {
      if (message.r === `${user2.id}`) {
        notifyUser(message.r, false);

        setMessages((m) => [...m, message]);
      } else if (message.s !== user2.id) {
        notifyUser(message.r, true);
      }
    });

    socket.on('disconnect', () => {
      console.log('disconnected');
    });
  }, [user1, user2]);

  useEffect(() => {
    getPrevMessages(queryObj);
  }, [queryObj, user2]);

  const sendMessage = (message) => {
    if (socket && socket.connected) {
      const chMessage = {
        r: `${user2.id}`,
        s: user1.id,
        m: message,
      };
      socket.emit('message', chMessage);
    } else {
      socket.emit('message', { message: message, status: 'failed' });
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      makeScrollTo(scrollRef, 'bottom');
    }
  }, [messages]);

  return (
    <div ref={scrollRef} className='w-full p-6 mx-auto custom-shadow max-h-[460px] overflow-auto'>
      <MiniNav title='Recent Chat' />
      {messages?.map((msg, index) => {
        if (msg.s === user1.id)
          return (
            <div key={index} className='flex items-baseline flex-row-reverse'>
              <img className='ml-4 rounded-full w-8 h-8' src={userPng} alt='' />
              <div className='mb-2 bg-primary-400 text-primary-700 rounded-lg max-w-md ml-auto'>
                <MassageBox pos='right'>
                  <p className='px-6 py-2 max-w-[230px]  text-right'>{msg.m}</p>
                </MassageBox>
              </div>
            </div>
          );
        else
          return (
            <div key={index} className='flex items-baseline'>
              <img className='mr-4 rounded-full w-8 h-8' src={userPng} alt='' />
              <div className='mb-2 bg-primary-200 text-primary-700 rounded-lg max-w-md mr-auto'>
                <MassageBox>
                  <p className='px-6 py-2 max-w-[230px]  text-left'>{msg.m}</p>
                </MassageBox>
              </div>
            </div>
          );
      })}

      <TextBox onSend={sendMessage} />
    </div>
  );
}
export default React.memo(ChatRoom);
// cusTomer Admin to superAdmin data passed viewed and saved to database
// superAdmin to cusTomerAdmin data passed viewed and but not saved to database
