import React from 'react';
import DeviceAddForm from '../../../components/common/forms/device-add-form/DeviceAddForm';

export default function AddDevice() {
  return (
    <div className='add-device'>
      <div className=' max-w-xl  mx-auto'>
        <DeviceAddForm />
      </div>
    </div>
  );
}
