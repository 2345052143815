import { createSlice } from '@reduxjs/toolkit';

const zonesSlice = createSlice({
  name: 'zones',
  initialState: { isLoading: false, list: [], isError: false },
  reducers: {
    zonesInit: (state) => {
      return { ...state, isLoading: true };
    },
    zonesSuccess: (state, action) => {
      return { ...state, list: action.payload.data, isLoading: false };
    },
    zonesFail: (state, action) => {
      return { ...state, list: [], isLoading: false, isError: action.payload.data };
    },
    zonesDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    zonesDelSuccess: (state, action) => {
      return {
        ...state,
        list: { ...state.list, data: state.list.data.filter((item) => !action.payload.data.includes(item.id)) },
        isLoading: false,
      };
    },
    zonesDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { zonesInit, zonesSuccess, zonesFail, zonesDelInit, zonesDelSuccess, zonesDelFail } = zonesSlice.actions;
export default zonesSlice.reducer;
