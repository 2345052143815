import React from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CUSTOMER_ADMIN, CUSTOMER_USER, getActiveDevices, getCompanies, refreshTime, SUPER_ADMIN } from '../../../apis/apis';
import SelectFromOption from '../../../components/basic/SelectedInput/SelectedInput';
import ActiveDeviceCard from '../../../components/common/cards/active-device';
import DisplayItems from '../../../components/common/displayItems';
import { checkEmptyObject } from '../../../components/common/forms/validator';
import NoData from '../../../components/common/no-data/NoData';
import Suspender from '../../../components/common/suspender/Suspender';

export default function ActiveDevices() {
  const navigate = useNavigate();
  const {
    user: {
      data: { id, user_type, company },
    },
  } = useSelector((state) => state.auth) || {};

  const [queryObj, updateQueryObject] = useState({
    page: 0,
    per_page: 100,
  });
  const [selCompany, setSelCompany] = useState({});
  const companyList = useSelector((state) => state.companies);
  const { isLoading, isError, list } = useSelector((state) => state.activeDevices);
  const callApi = useCallback(
    (param) => {
      if (user_type === SUPER_ADMIN) {
        return getActiveDevices({ ...param });
      } else if (user_type === CUSTOMER_ADMIN) {
        return getActiveDevices({ ...param, company_id: company.id });
      } else {
        return getActiveDevices({ ...param, company_id: company.id, user_id: id });
      }
    },
    [user_type]
  );

  useEffect(() => {
    if (!checkEmptyObject(selCompany)) {
      updateQueryObject({ ...queryObj, company_id: selCompany.id });
    }
  }, [selCompany]);

  useEffect(() => {
    callApi(queryObj);
    const event = setInterval(() => {
      callApi(queryObj);
    }, refreshTime);
    return () => clearInterval(event);
  }, [queryObj]);

  useEffect(() => {
    if (user_type === SUPER_ADMIN) {
      getCompanies();
    }
  }, []);

  return (
    <Suspender isError={isError} isLoading={isLoading}>
      {user_type === SUPER_ADMIN && (
        <div className='w-[300px] my-6 mx-auto  lg:ml-3'>
          <SelectFromOption
            onClick={(op) => setSelCompany(op)}
            value={selCompany}
            options={companyList.list || []}
            headerClass='header-class'
            placeholder='Select Company'
          />
        </div>
      )}
      <div className='active-devices'>
        <DisplayItems
          items={list.data || []}
          children={(props) => <ActiveDeviceCard {...props} onClick={(item) => navigate(`logs/${item.pg_number}/${item.device_id}`)} />}
        />
        {list.data?.length === 0 && <NoData />}
      </div>
    </Suspender>
  );
}
