import React from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressCircle from '../../../../../components/basic/progress-circle/ProgressCircle';

export default function TotalDevices(props) {
  const navigate = useNavigate();
  return (
    <div onClick={() => navigate('device/active-devices')} className='total-devices cursor-pointer'>
      <ProgressCircle width={100} height={100} barWidth={20} bgBar='#666666' bgOuter='#DFE7ED' currentPercent={60}>
        <h2 className='text-primary text-4xl'>{props.data || ''}</h2>
      </ProgressCircle>
    </div>
  );
}
