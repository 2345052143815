import React from 'react';
import './Input.css';
import PropTypes from 'prop-types';

const Input = (props) => {
  const { placeholder, readOnly = false, className, type, name, onChange, validation, fieldError, value } = props;
  const controlChange = (e) => {
    const { name = '', value = '' } = e.target;
    if (validation) {
      const err = validation(name, value);
      onChange(name, { value: value, error: err });
    } else {
      onChange(name, { value: value, error: false });
    }
  };
  return (
    <>
      <div className={`input-wrapper ${className}`}>
        <input
          readOnly={readOnly}
          name={name}
          value={value}
          onChange={(e) => controlChange(e)}
          placeholder={placeholder}
          type={type}
        />
      </div>
      {fieldError && <label className='text-[#dc5c5c] text-sm'>{fieldError}</label>}
    </>
  );
};
export default Input;

Input.propTypes = {
  validation: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  border: PropTypes.string,
  fontSize: PropTypes.string,
  placeholder: PropTypes.string,
  brRadius: PropTypes.string,
  height: PropTypes.string,
  type: PropTypes.string,
};
